import { GET_PLANS } from '../actions/actionTypes';

const initialState = {
  plans: [
    {
      id: 0,
      name: 'Standard',
      duration: 1,
      discription:
        'Build the operational muscle your company needs to continue its growth',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      price: 10,
      isBest: false,
    },
    {
      id: 1,

      name: 'Growth',
      duration: 6,
      discription:
        'Support your growing business with a complete expense management tool to control your spending and increase efficiency',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      isBest: true,
      price: 9,
    },
    {
      id: 2,

      name: 'Advanced',
      duration: 12,
      discription: 'A very smart plan for less spending and more saving.',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      isBest: false,
      price: 8,
    },
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS:
      return {
        ...state,
        plans: action.plans,
      };

    default:
      return state;
  }
};

export default reducer;
