import React from "react";
import { useTranslation } from "react-i18next";
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Avatar from "antd/lib/avatar";
import { CgQuote } from "react-icons/cg";
import Marquee from "react-fast-marquee";

import reviewer from "../../../../assets/images/human.png";
import { reviews } from "./reviews";

import "./reviewsMasonry.css";

const ReviewsMasonry = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage masonry" style={{ direction: "ltr" }}>
      <h1 className="section-title">
        {t("More than 3000 Clients, you would want to hear from them")}
      </h1>
      <p
        className="review-text"
        id="review-text-1"
        style={{ textAlign: "center" }}
      >
        {t(
          "But dont just take our word for it, see what our customers have to say"
        )}
      </p>
      <Marquee speed={40} gradientWidth={40} pauseOnHover>
        {reviews.map((review, i) => {
          return (
            <div className="masonry-review" key={i + "key"}>
              <p className="review-text" id="review-text">
                <CgQuote />
                <span>{review.review}</span>
                <CgQuote />
              </p>
              <div className="reviewer">
                <Avatar
                  src={review.picture === "" ? reviewer : review.picture}
                  alt="reviewer"
                  size={60}
                  id="reviwer-avatar"
                />
                <div className="reviewer-info">
                  <h3 id="reviwer-name">{review.author}</h3>
                  <span id="reviwer-job">{review.job}</span>
                </div>
              </div>
            </div>
          );
        })}
      </Marquee>
      {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}>
        <Masonry>
          {reviews.map((review, i) => {
            return (
              <div className="masonry-review" key={i + "key"}>
                <p className="review-text" id="review-text">
                  <CgQuote />
                  <span>{review.review}</span>
                  <CgQuote />
                </p>
                <div className="reviewer">
                  <Avatar
                    src={review.picture === "" ? reviewer : review.picture}
                    alt="reviewer"
                    size={60}
                    id="reviwer-avatar"
                  />
                  <div className="reviewer-info">
                    <h3 id="reviwer-name">{review.author}</h3>
                    <span id="reviwer-job">{review.job}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Masonry>
      </ResponsiveMasonry> */}
    </section>
  );
};

export default ReviewsMasonry;
