import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BasicsFAQs from "./questionsGroups/basicsFAQs";
import UsingHollydesk from "./questionsGroups/usingHollydesk";
import KnowMore from "../../pricing/knowMore/knowMore";
import TrustedBy from "../../home/trustedBy/trustedBy";

import "./FAQs.css";

const FAQs = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  const { t } = useTranslation();

  return (
    <Fragment>
      <section className="app-features-wrapper">
        <div className="mainPage FAQs-section">
          <h1 className="section-title" id="FAQs-section-title">
            {t("Frequently Asked Questions")}
          </h1>
          <h2 className="question-group-title" id="question-group-title-1">
            {t("Basics")}
          </h2>
          <BasicsFAQs />
          <h2 className="question-group-title" id="question-group-title-2">
            {t("Using Hollydesk")}
          </h2>
          <UsingHollydesk />
        </div>
      </section>
      <KnowMore />
      <TrustedBy />
    </Fragment>
  );
};

export default FAQs;
