import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import { DownOutlined } from "@ant-design/icons";
import Divider from "antd/lib/divider";

const ResourcesMenu = () => {
  const [dir, setDir] = useState("ltr");

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const location = useLocation();

  useEffect(() => {
    lang === "ar" ? setDir("rtl") : setDir("ltr");
  }, [lang]);

  const menu = (
    <Menu>
      <Menu.Item key="0" style={{ direction: dir }}>
        <NavLink to="/resources/blog" id="mainNav-link-blog">
          {t("Blog")}
        </NavLink>
      </Menu.Item>
      <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="1" style={{ direction: dir }}>
        <NavLink to="/resources/FAQs" id="mainNav-link-faqs">
          {t("FAQs")}
        </NavLink>
      </Menu.Item>
      <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="2" style={{ direction: dir }}>
        <a
          href={`https://intercom.help/hollydesk/${lang}/`}
          target="_blank"
          rel="noreferrer"
          id="mainNav-link-helpdesk"
        >
          {t("Helpdesk")}
        </a>
      </Menu.Item>
      {/* <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="3" style={{ direction: dir }}>
        <NavLink to="/resources/calculator" id="mainNav-link-calculator">
          {t("Calculator")}
        </NavLink>
      </Menu.Item> */}
      <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="4" style={{ direction: dir }}>
        <NavLink to="/resources/referral" id="mainNav-link-referral">
          {t("Referral")}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className={
          location.pathname === "/resources/blog" ||
          location.pathname === "/resources/FAQs" ||
          location.pathname === "/resources/calculator" ||
          location.pathname === "/resources/referral"
            ? "ant-dropdown-link nav-bar-item-active"
            : "ant-dropdown-link nav-bar-item"
        }
        onClick={(e) => e.preventDefault()}
        id="mainNav-link-resources"
      >
        {t("Resources")} <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default ResourcesMenu;
