import React from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

import step1 from "../../../../../assets/images/2-friends.png";
import step2 from "../../../../../assets/images/help-agent.png";
import step3 from "../../../../../assets/images/gift-box.png";

import "./referralSteps.css";

const ReferralSteps = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage digitalizeSection">
      <h1 className="section-title digitalize-title" id="digitalize-title">
        {t("How Does It Work?")}
      </h1>
      <div className="digital-cards">
        <div className="digital-section-card">
          <Fade direction="up" delay={30} triggerOnce>
            <img
              src={step1}
              alt="digital-section-card"
              id="digital-section-card-pic1"
            />
            <h1 id="digital-section-card-title1">{t("Step 1")}</h1>
            <p id="digital-section-card-disc1">
              {t("Fill in your friend’s details below")}
            </p>
          </Fade>
        </div>
        <div className="digital-section-card">
          <Fade direction="up" delay={30} triggerOnce>
            <img
              src={step2}
              alt="digital-section-card"
              id="digital-section-card-pic2"
            />
            <h1 id="digital-section-card-title2">{t("Step 2")}</h1>
            <p id="digital-section-card-disc2">
              {t("We’ll help set up their account")}
            </p>
          </Fade>
        </div>
        <div className="digital-section-card">
          <Fade direction="up" delay={30} triggerOnce>
            <img
              src={step3}
              alt="digital-section-card"
              id="digital-section-card-pic3"
            />
            <h1 id="digital-section-card-title3">{t("Step 3")}</h1>
            <p id="digital-section-card-disc3">
              {t(
                "Once they start using Hollydesk you’ll get 500 EGP as a reward!"
              )}
            </p>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default ReferralSteps;
