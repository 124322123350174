import React, { Fragment, useEffect } from "react";

import MainBanner from "./mainBanner/mainBanner";
import FeaturedIn from "./featuredIn/featuredIn";
import DigitalizeSection from "./digitalizeSection/digitalizeSection";
import AppFeatures from "./appFeatures/appFeatures";
import TrustedBy from "./trustedBy/trustedBy";
import LowerBanner from "./lowerBanner/lowerBanner";

const Home = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Fragment>
      <MainBanner />
      <FeaturedIn />
      <DigitalizeSection />
      <AppFeatures />
      <TrustedBy />
      <LowerBanner />
    </Fragment>
  );
};

export default Home;
