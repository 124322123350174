import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import OpengraphReactComponent from 'opengraph-react';

import ArticleBanner from '../articleBanner/articleBanner';
import ArticleCard from '../articleCard/articleCard';
import BlogForm from '../blogForm/blogForm';
import facebook from '../../../../../assets/images/facebook.png';
import twitter from '../../../../../assets/images/twitter.png';
import linkedin from '../../../../../assets/images/linkedin.png';
import email from '../../../../../assets/images/email.png';
import HelmetMetaData from './helmetMetaData';
import logo from '../../../../../assets/images/hollydesk-logo.png';
import { getArticles } from '../../../../../store/actions/blogActions';

import './articlePage.css';
import api from '../../../../../api';

const ArticlePage = () => {
  const articles = useSelector((state) => state.blog.articles);

  const [currentArticle, setCurrentArticle] = useState({});

  const params = useParams();

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const getCurrentArticle = () =>
    api
      .get(`/admin/blogs/${params.articleId}`)
      .then((res) => setCurrentArticle(res.data.info))
      .catch((err) => console.log(err));

  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentArticle();
    dispatch(getArticles());
    window.scrollTo(0, 0);
  }, [params.articleId]);

  const shareUrl = window.location.href;

  const moreArticles = articles.slice(0, 3);

  window.onscroll = () => {
    const headerId = document.getElementById('article-header');
    if (headerId) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        headerId.style.top = '0px';
      } else {
        headerId.style.top = '-64px';
      }
    }

    const barId = document.getElementById('myBar');
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    if (barId) {
      barId.style.width = scrolled + '%';
    }
  };

  const createMarkup = () => {
    return {
      __html:
        lang === 'ar' ? currentArticle?.contentAr : currentArticle?.content,
    };
  };

  return (
    <section className="mainPage">
      <OpengraphReactComponent
        site={'site that you want to load (url encoding is done for you)'}
        appId={'64cf3f83-3194-4821-a85f-fa28979f3bd8'}
        size={'small'}
      />
      <HelmetMetaData currentArticle={currentArticle} shareUrl={shareUrl} />
      <div id="article-header">
        <div className="article-header-content">
          <img src={logo} alt="logo" id="logoo" />
          <h3 style={{ marginBottom: '0px' }}>
            {lang === 'ar' ? currentArticle?.titleAr : currentArticle?.title}
          </h3>
          <Link
            to="/book-a-demo"
            className="btn btnGreenFill"
            id="mainNav-demoBooking"
          >
            {t('Book a demo')}
          </Link>
        </div>
        <div class="progress-container">
          <div class="progress-bar" id="myBar"></div>
        </div>
      </div>
      <ArticleBanner article={currentArticle} showBtn={false} />
      <div
        className="article-content"
        dangerouslySetInnerHTML={createMarkup()}
      />
      <div className="share-article">
        <p className="share-article-line">{t('Share article')}</p>
        <FacebookShareButton
          beforeOnClick={() => {
            document.getElementById('meta_description').content =
              'TEAS CHANGE BY ID';
          }}
          onShareWindowClose={() => {
            document.getElementById('meta_description').content =
              "Hollydesk takes care of your company's daily expenses and accounts payable all on one platform with a built-in wallet";
          }}
          url={shareUrl}
        >
          <img src={facebook} alt="shareicon" />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <img src={twitter} alt="shareicon" />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl}>
          <img src={linkedin} alt="shareicon" />
        </LinkedinShareButton>
        <EmailShareButton url={shareUrl}>
          <img src={email} alt="shareicon" />
        </EmailShareButton>
      </div>
      <p className="more-article-line" id="more-article-line">
        {t('You may also like to read')}
      </p>
      <div className="article-cards-container">
        {moreArticles.map((art, i) => {
          return <ArticleCard article={art} key={i} />;
        })}
      </div>
      <BlogForm />
    </section>
  );
};

export default ArticlePage;
