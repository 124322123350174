import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "antd/lib/slider";
import message from "antd/lib/message";
import { useForm } from "react-hook-form";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/en/world.json";

import api from "../../../../../api";

import "./calculationInputs.css";

const CalculationInputs = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [stage, setStage] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [employees, setEmployees] = useState(0);
  const [reports, setReports] = useState(1);
  const [expense, setExpense] = useState(50);
  const [budget, setBudget] = useState(3000);
  const [reverse, setReverse] = useState(false);
  const [mobile, setMobile] = useState({ short: "EG", phone: "" });
  const [phoneErr, setPhoneErr] = useState(false);

  const {
    employeesHandler,
    reportsHandler,
    expenseHandler,
    budgetHandler,
    companyHandler,
    stageHandler,
  } = props;

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    lang === "ar" ? setReverse(true) : setReverse(false);
  }, [lang]);

  const startHandler = () => {
    return companyName === ""
      ? message.warn(t("Enter the company name"))
      : (setStage(2), stageHandler(2));
  };

  const bookDemoRequest = (data) => {
    api
      .post("/admin/demo-request", data)
      .then((res) => {
        message.success("SENT SECCESSFULLY");
        reset();
        setMobile({ short: "EG", phone: "" });
      })
      .catch((err) => message.error("FAILED"));
  };

  const onSubmit = (data) => {
    let correctedMobile;
    return Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
      ? ((correctedMobile = mobile.phone),
        (data.MobileNumber = mobile.code + correctedMobile),
        (data.companyName = companyName),
        bookDemoRequest(data))
      : // This case has no meaning BUT added according to the tester opnion
      Object.keys(mobile).length !== 0 &&
        mobile.short === "EG" &&
        mobile.phone.length === 11
      ? ((correctedMobile = mobile.phone.substring(1)),
        (data.MobileNumber = mobile.code + correctedMobile),
        (data.companyName = companyName),
        bookDemoRequest(data))
      : (setPhoneErr(true), message.error("WRONG MOBILE"));
  };

  return (
    <div className="calculationInputs">
      <h1
        className="section-title calculationInputs-title"
        id="calculationInputs-title"
      >
        {t("Check out how much you can save by using Hollydesk")}
      </h1>
      {stage === 1 ? (
        <div className="form-element-full">
          <p className="calculationInputs-desc" id="calculationInputs-desc-1">
            {t(
              "This calculator helps you measure how much you could save when you use Hollydesk for your expense management processes."
            )}
          </p>
          <label id="company-name-label">{t("Enter your company name?")}</label>
          <input
            className="ant-input"
            id="company-name-input"
            value={companyName}
            placeholder={t("Ex: Hollydesk")}
            onChange={(e) => {
              companyHandler(e.target.value);
              setCompanyName(e.target.value);
            }}
          />
          <button
            className="btn btnPrimary calc-start-btn"
            id="calc-start-btn"
            onClick={startHandler}
          >
            {t("Start")}
          </button>
        </div>
      ) : stage === 2 ? (
        <Fragment>
          <div className="question-range-group">
            <p id="question-number-1">{t("Question 1")}</p>
            <h3 id="question-desc-1">
              {t("How many employees spend money in your company?")}
            </h3>
            <div className="range-group">
              <Slider
                className="calculator-slider"
                id="calculator-slider-1"
                value={employees}
                onChange={(value) => {
                  employeesHandler(value);
                  setEmployees(value);
                }}
                reverse={reverse}
                min={0}
                max={500}
              />
              <input
                className="slider-value"
                id="slider-value-1"
                type="number"
                value={employees}
                onChange={(e) => setEmployees(e.target.value)}
              />
            </div>
          </div>

          <div className="question-range-group">
            <p id="question-number-2">{t("Question 2")}</p>
            <h3 id="question-desc-2">
              {t("How many expense reports do employees file each month?")}
            </h3>
            <div className="range-group">
              <Slider
                className="calculator-slider"
                id="calculator-slider-2"
                value={reports}
                onChange={(value) => {
                  reportsHandler(value);
                  setReports(value);
                }}
                reverse={reverse}
                min={1}
                max={100}
              />
              <input
                className="slider-value"
                id="slider-value-2"
                type="number"
                value={reports}
                onChange={(e) => setReports(e.target.value)}
              />
            </div>
          </div>

          <div className="question-range-group">
            <p id="question-number-3">{t("Question 3")}</p>
            <h3 id="question-desc-3">
              {t("What is the average expense claim amount?")}
            </h3>
            <div className="range-group">
              <Slider
                className="calculator-slider"
                id="calculator-slider-3"
                value={expense}
                onChange={(value) => {
                  expenseHandler(value);
                  setExpense(value);
                }}
                reverse={reverse}
                min={50}
                max={500}
              />
              <input
                className="slider-value"
                id="slider-value-3"
                type="number"
                value={expense}
                onChange={(e) => setExpense(e.target.value)}
              />
            </div>
          </div>

          <div className="question-range-group">
            <p id="question-number-4">{t("Question 4")}</p>
            <h3 id="question-desc-4">
              {t("What is the average monthly salary? (EGP)")}
            </h3>
            <div className="range-group">
              <Slider
                className="calculator-slider"
                id="calculator-slider-4"
                value={budget}
                onChange={(value) => {
                  budgetHandler(value);
                  setBudget(value);
                }}
                reverse={reverse}
                min={3000}
                max={50000}
              />
              <input
                className="slider-value"
                id="slider-value-4"
                type="number"
                value={budget}
                min="3000"
                onChange={(e) => setBudget(e.target.value)}
              />
            </div>
          </div>
          <div className="calc-controls">
            <button
              className="btn btnSecondary calculator-control"
              id="calculator-control-1"
              onClick={() => {
                setStage(1);
                stageHandler(1);
                window.scrollTo(0, 0);
              }}
            >
              {t("Previous")}
            </button>
            <button
              className="btn btnPrimary calculator-control"
              id="calculator-control-2"
              onClick={() => {
                setStage(3);
                stageHandler(3);
                window.scrollTo(0, 0);
              }}
            >
              {t("Next")}
            </button>
          </div>
        </Fragment>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="calculationInputs-desc" id="calculationInputs-desc-2">
            {t(
              "Empower your teams and put finance in control, Book your free demo of Hollydesk"
            )}
          </p>
          <div className="form-element-full">
            <label id="name-label">{t("Name")}</label>
            <input
              className="ant-input"
              id="name-input"
              pattern="[a-zA-Z\s]+"
              title="Only letters are allowed"
              placeholder={t("Ex: Mohamed Ahmed")}
              {...register("name", { required: true })}
            />
            {errors.name && <span className="form-err">Name is required</span>}
          </div>
          <div className="form-element-full">
            <label id="email-label">{t("Email")}</label>
            <input
              className="ant-input"
              id="email-input"
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              title="Email format should be like sometext@website.domain"
              placeholder={t("Ex: someone@hollydesk.com")}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="form-err">Email is required</span>
            )}
          </div>
          <div className="form-element-full">
            <label id="mobile-label">{t("Mobile Number")}</label>
            <ConfigProvider
              locale={en}
              areaMapper={(area) => {
                return {
                  ...area,
                  emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
                };
              }}
            >
              <CountryPhoneInput
                defaultValue={{ short: "EG" }}
                id="mobile-input"
                type="number"
                placeholder="1234567890"
                value={mobile}
                onChange={(v) => {
                  setPhoneErr(false);
                  setMobile(v);
                }}
              />
            </ConfigProvider>
            {phoneErr && (
              <span className="form-err">Enter a valid Mobile number</span>
            )}
          </div>
          <div className="calc-controls">
            <button
              className="btn btnSecondary calculator-control"
              id="calculator-control-3"
              type="button"
              onClick={() => {
                setStage(2);
                stageHandler(2);
                window.scrollTo(0, 0);
              }}
            >
              {t("Previous")}
            </button>
            <button
              className="btn btnPrimary calculator-control"
              id="calculator-control-4"
              type="submit"
            >
              {t("Submit")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default CalculationInputs;
