import React from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

import employeeVector from "../../../../assets/images/Vector-employee.png";
import managerVector from "../../../../assets/images/Vector-manager.png";
import financeVector from "../../../../assets/images/Vector-finance.png";

import "./digitalizeSection.css";

const DigitalizeSection = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage digitalizeSection">
      <h1 className="section-title digitalize-title" id="digitalize-title">
        {t(
          "Digitalize and automate your financial process and get everyone to"
        )}{" "}
        <span className="digitalize-background">{t("save time")}</span>
      </h1>
      <div className="digital-cards">
        <div className="digital-section-card">
          <Fade direction="up" delay={30} triggerOnce>
            <img
              src={employeeVector}
              alt="digital-section-card"
              id="digital-section-card-pic1"
            />
            <h1 id="digital-section-card-title1">{t("Employees")}</h1>
            <p id="digital-section-card-disc1">
              {t(
                "Collect and upload all your receipts into the expense record anywhere easily and get your money back instantly."
              )}
            </p>
          </Fade>
        </div>
        <div className="digital-section-card">
          <Fade direction="up" delay={30} triggerOnce>
            <img
              src={managerVector}
              alt="digital-section-card"
              id="digital-section-card-pic2"
            />
            <h1 id="digital-section-card-title2">{t("Managers")}</h1>
            <p id="digital-section-card-disc2">
              {t(
                "Validate or decline expenses digitally with just a click to speed up your expense process."
              )}
            </p>
          </Fade>
        </div>
        <div className="digital-section-card">
          <Fade direction="up" delay={30} triggerOnce>
            <img
              src={financeVector}
              alt="digital-section-card"
              id="digital-section-card-pic3"
            />
            <h1 id="digital-section-card-title3">{t("Finance")}</h1>
            <p id="digital-section-card-disc3">
              {t(
                "Track all the company’s expenses as well as process payments and invoices with real-time reporting."
              )}
            </p>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default DigitalizeSection;
