import report1 from "../../../../assets/images/report1.png";
import report2 from "../../../../assets/images/report2.png";
import report3 from "../../../../assets/images/report3.png";
import report4 from "../../../../assets/images/report4.png";

export const reporting = {
  name: "Reporting",
  discription:
    "Your expense reports will be made automatically in real-time and efficiently and with no risks",
  content: [
    {
      keyName: "Exported as a CSV",
      title: "Expense reporting made easily",
      paragraph:
        'Reports are now created and updated automatically each time a spending process occurs. Your reports can be exported as a CSV file that fits with your accounting software such as "XERO, QUICKBOOKS, FRESHBOOKS"',
      photo: report1,
    },
    {
      keyName: "Everything done digitally",
      title: "No manual data entry",
      paragraph:
        "No more creating reports manually, simply because everything is being done digitally, the employees snap the receipt then an expense claim will be created automatically, which saves more time for both the employees and finance team",
      photo: report2,
    },
    {
      keyName: "Automatically save",
      title: "Expense history",
      paragraph:
        "All expenses and payment processes are being automatically saved and available to be easily viewed and tracked with a click",
      photo: report3,
    },
    {
      keyName: "View all insights",
      title: "View insights and make better decisions",
      paragraph:
        "Get all the insights you need seamlessly from your dashboard and by choosing the category you want to view, which helps you in taking better decisions",
      photo: report4,
    },
  ],
};
