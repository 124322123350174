import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./lowerBanner.css";

const LowerBanner = () => {
  const { t } = useTranslation();

  return (
    <section className="lowerBanner-wrapper">
      <div className="mainPage lowerBanner">
        <h1 className="section-title lowerBanner-title" id="lowerBanner-title">
          {t("A simple")}{" "}
          <span className="lowerBanner-background">{t("solution")}</span>{" "}
          {t("for all your company spending")}
        </h1>
        <div className="lowerBanner-buttons">
          <Link
            to="/book-a-demo"
            className="btn btnPrimary"
            id="book-a-demo-btn"
          >
            {t("Book a demo")}
          </Link>
          <a
            href="https://www.app.hollydesk.com/create"
            target="_blank"
            rel="noreferrer"
            className="btn btnSecondary"
            id="try-it-out-btn"
            style={{ color: "#1D2B36" }}
          >
            {t("Try it out")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default LowerBanner;
// A simple
