import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "antd/lib/menu";
import Divider from "antd/lib/divider";
import Dropdown from "antd/lib/dropdown";
import { DownOutlined } from "@ant-design/icons";

import "./drawerMenu.css";

const DrawerMenu = ({ closeHandler }) => {
  const [dir, setDir] = useState("ltr");
  const [openKeys, setOpenKeys] = useState(["sub3"]);
  const rootSubmenuKeys = ["sub1", "sub2"];

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const navigate = useNavigate();

  const langChangeHandler = (lang) => i18n.changeLanguage(lang);

  useEffect(() => {
    lang === "ar" ? setDir("rtl") : setDir("ltr");
    var all = document.getElementsByClassName("ant-menu-submenu-arrow");
    for (var i = 0; i < all.length; i++) {
      all[i].style.right = lang === "ar" ? "auto" : "16px";
      all[i].style.left = lang === "ar" ? "16px" : "auto";
    }
  }, [lang]);

  const { SubMenu } = Menu;

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          langChangeHandler("ar");
        }}
      >
        <span className="lang-name">{t("Arabic")}</span>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          langChangeHandler("en");
        }}
      >
        <span className="lang-name">{t("English")}</span>
      </Menu.Item>
    </Menu>
  );

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div>
      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
        <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
          <Link to="/">{t("home")}</Link>
        </Menu.Item>

        <Divider style={{ margin: "4px 0px" }} />

        <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
          <Link to="/howItWorks">{t("How it works")}</Link>
        </Menu.Item>

        <Divider style={{ margin: "4px 0px" }} />

        <SubMenu key="sub1" title={t("Features")} style={{ direction: dir }}>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/features/expense-reimbursement">
              {t("Expense Reimbursements")}
            </Link>
          </Menu.Item>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/features/in-advance-payment">
              {t("In Advance Payment")}
            </Link>
          </Menu.Item>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/features/supplier-management">
              {t("Supplier management")}
            </Link>
          </Menu.Item>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/features/reporting">{t("Reporting")}</Link>
          </Menu.Item>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/features/business-credit">{t("Business Credit")}</Link>
          </Menu.Item>
        </SubMenu>

        <Divider style={{ margin: "4px 0px" }} />

        <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
          <Link to="/pricing">{t("Pricing")}</Link>
        </Menu.Item>

        <Divider style={{ margin: "4px 0px" }} />

        <SubMenu key="sub2" title={t("Resources")} style={{ direction: dir }}>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/resources/blog">{t("Blog")}</Link>
          </Menu.Item>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/resources/FAQs">{t("FAQs")}</Link>
          </Menu.Item>
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <a
              href={`https://intercom.help/hollydesk/${lang}/`}
              target="_blank"
              rel="noreferrer"
              id="mainNav-link-helpdesk"
            >
              {t("Helpdesk")}
            </a>
          </Menu.Item>
          {/* <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/resources/calculator">{t("Calculator")}</Link>
          </Menu.Item> */}
          <Menu.Item onClick={closeHandler} style={{ direction: dir }}>
            <Link to="/resources/referral">{t("Referral")}</Link>
          </Menu.Item>
        </SubMenu>

        <Divider style={{ margin: "4px 0px" }} />
      </Menu>

      <button
        className="btn btnPrimary mobile-menu-btn"
        id="mobile-book-a-demo-btn"
        onClick={() => {
          navigate("/book-a-demo");
          closeHandler();
        }}
      >
        {t("Book a demo")}
      </button>
      <button
        className="btn btnSecondary mobile-menu-btn"
        id="mobile-log-in-btn"
        onClick={() => {
          closeHandler();
          window.location.href = "https://www.app.hollydesk.com/login";
        }}
      >
        {t("Login")}
      </button>

      <div className="footer-lang" style={{ direction: dir }}>
        <span>{t("Language")}:</span>
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className="lang-option nav-bar-item">
            <span className="lang-name">
              {lang === "ar" ? t("Arabic") : t("English")}
            </span>
            <DownOutlined className="lang-icon" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default DrawerMenu;
