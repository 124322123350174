import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import knowMoreIMG from "../../../../assets/images/know-more-section.png";

import "./knowMore.css";

const KnowMore = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <section className="mainPage knowMoreSection">
      <div className="know-more-content">
        <h2 id="know-more-text1">{t("Want to know more?")}</h2>
        <h1 id="know-more-text2">{t("We here 24/7 to help you")}</h1>
        <p id="know-more-text3">
          {t(
            "Let’s show you around and tell you more about Hollydesk, contact us at"
          )}{" "}
          {""}
          <span style={{ color: "#60C68F" }}>family@hollydesk.com</span>
        </p>
        <button
          className="btn btnPrimary"
          id="contactUs-btn"
          onClick={() => navigate("/book-a-demo")}
        >
          {t("Contact us")}
        </button>
      </div>
      <img src={knowMoreIMG} alt="knowMoreImage" id="know-more-image" />
    </section>
  );
};

export default KnowMore;
