import React from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

import { FcCheckmark } from "react-icons/fc";
import devices from "../../../../assets/images/devices.png";

import "./loveHollyDesk.css";

const LoveHollyDesk = () => {
  const reasons = [
    "Increases efficiency",
    "Increases loyalty",
    "Empowers teams",
    "Fast & easy invoice Payments",
    "Real-time reporting",
    "Saves time & money",
  ];

  const { t } = useTranslation();

  return (
    <section className="mainPage">
      <Fade direction="up" delay={30} triggerOnce>
        <div className="loveHollyDeskSection">
          <h1
            className="section-title loveHollyDesk-title"
            id="loveHollyDesk-title"
          >
            {t("Why everybody love Hollydesk?")}
          </h1>
          <div className="loveHollyDesk">
            <div className="reasons">
              {reasons.length > 0
                ? reasons.map((res, i) => {
                    return (
                      <span
                        className="reasons-element"
                        id={`reasons-element-${i + 1}`}
                        key={i}
                      >
                        <FcCheckmark /> &nbsp; {t(res)}
                      </span>
                    );
                  })
                : ""}
            </div>
            <img src={devices} alt="devices" id="loveHollyDeskSection-image" />
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default LoveHollyDesk;
