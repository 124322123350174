import React from "react";
import { useTranslation } from "react-i18next";

import "./calculatorScreen.css";

const CalculatorScreen = (props) => {
  const { employees, reports, expense, budget, company, stage } = props;

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <div className="calc-screen">
      <h3 className="calc-screen-company" id="calc-screen-company">
        {company === "" ? t("Enter company name to start") : company}
      </h3>
      <div
        className="calc-sreen-qrtr qrtr-bottom qrtr-1"
        style={
          lang === "ar"
            ? { borderLeft: "2px solid #56c288" }
            : { borderRight: "2px solid #56c288" }
        }
      >
        <h3 className="screen-title" id="screen-title-1">
          {t("Time spent on expense reports")}
        </h3>
        <span className="screen-value" id="screen-value-1">
          {stage === 1 ? "_" : employees}
        </span>
        <p className="screen-unit" id="screen-unit-1">
          {t("hours per year")}
        </p>
      </div>
      <div className="calc-sreen-qrtr qrtr-bottom qrtr-2">
        <h3 className="screen-title" id="screen-title-2">
          {t("Money spent on expense reports")}
        </h3>
        <span className="screen-value" id="screen-value-2">
          {stage === 1 ? "_" : reports}
        </span>
        <p className="screen-unit" id="screen-unit-3">
          {t("Per year")}
        </p>
      </div>
      <div
        className="calc-sreen-qrtr qrtr-3"
        style={
          lang === "ar"
            ? { borderLeft: "2px solid #56c288" }
            : { borderRight: "2px solid #56c288" }
        }
      >
        <h3 className="screen-title" id="screen-title-3">
          {t("Total time saved by using Hollydesk")}
        </h3>
        <span className="screen-value" id="screen-value-3">
          {stage === 1 ? "_" : expense}
        </span>
        <p className="screen-unit" id="screen-unit-3">
          {t("hours per year")}
        </p>
      </div>
      <div className="calc-sreen-qrtr qrtr-4">
        <h3 className="screen-title" id="screen-title-4">
          {t("Total money saved by using Hollydesk")}
        </h3>
        <span className="screen-value" id="screen-value-4">
          {stage === 1 ? "_" : budget}
        </span>
        <p className="screen-unit" id="screen-unit-4">
          {t("Per year")}
        </p>
      </div>
    </div>
  );
};

export default CalculatorScreen;
