import React, { useEffect } from "react";

import VideoSection from "./videoSection/videoSection";
import Steps from "./steps/steps";
import FirstBanner from "./firstBanner/firstBanner";
import LoveHollyDesk from "./loveHollyDesk/loveHollyDesk";
import SecondBanner from "./secondBanner/secondBanner";

const HowItWorks = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div>
      <VideoSection />
      <Steps />
      <FirstBanner />
      <LoveHollyDesk />
      <SecondBanner />
    </div>
  );
};

export default HowItWorks;
