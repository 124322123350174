import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcCheckmark } from 'react-icons/fc';
import { ImFire } from 'react-icons/im';
import { Zoom } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';

import './pricingCard.css';

const PricingCard = (props) => {
  const { currency } = props;

  const [saveAmount, setSaveAmount] = useState(0);

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    console.log(props.plan);
    setSaveAmount(
      props.plan.duration == 6 ? 10 : props.plan.duration == 12 ? 20 : 0
    );
  }, []);

  const billTime = () =>
    props.plan.duration == 1 ? (
      <h6 className="plan-billTime">{t('Per active user/ Billed monthly')}</h6>
    ) : props.plan.duration == 6 ? (
      <h6 className="plan-billTime">
        {t('Per active user/ Billed every 6 month')}
      </h6>
    ) : props.plan.duration == 12 ? (
      <h6 className="plan-billTime">
        {t('Per active user/ Billed every year')}
      </h6>
    ) : (
      ''
    );

  return (
    <div className="plan-card">
      <Zoom triggerOnce>
        <span className="name-save">
          <h5
            className="plan-name"
            id={`PlanCard-name-${props.id}`}
            style={{ fontWeight: 'bold', marginBottom: '0px' }}
          >
            {t(props.plan.name)}
          </h5>
          <p
            style={{ display: saveAmount == 0 ? 'none' : 'block' }}
            id={`PlanCard-mostPopular-${props.id}`}
            className="most-popular"
          >
            {t('Save')} {saveAmount}%
          </p>
        </span>
        <h3 className="plan-price" id={`PlanCard-price-${props.id}`}>
          {props.plan.price} {t(currency)}{' '}
          <span className="month">/ {t('Month')}</span>
        </h3>
        {billTime()}

        <p id={`PlanCard-desc-${props.id}`} className="plan-desc">
          {t(props.plan.discription)}
        </p>
        {props.plan.utilities.map((benf, i) => {
          return (
            <span
              id={`PlanCard-benifit-${props.id}-${i}`}
              className="benifit"
              key={props.plan.utilities.indexOf(benf)}
              style={{ color: '#6B7280' }}
            >
              <FcCheckmark style={{ fontSize: '20px' }} />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;{' '}
              <p
                id={`PlanCard-benifit-${props.id}-${i + 100}`}
                style={{ marginBottom: '0px' }}
              >
                {t(benf)}
              </p>
            </span>
          );
        })}
        {props.subscribed ? (
          <button
            id={`PlanCard-button-${props.id}`}
            disabled={props.currentPlan.price === props.plan.price}
            className={
              props.plan.isBest ? 'btn btnPrimary' : 'btn btnPrimaryHovered'
            }
            // onClick={() =>
            //   props.currentPlan.price > props.plan.price
            //     ? history.push(`/dashboard/billingPlan/upgrade/${props.plan.id}`)
            //     : ""
            // }
          >
            {props.currentPlan.price === props.plan.price
              ? 'Current Plan'
              : props.currentPlan.price > props.plan.price
              ? `Upgrade to ${props.plan.name}`
              : 'Subscribe'}
          </button>
        ) : (
          <button
            id={`PlanCard-button-${props.id}`}
            className={
              props.index === 1
                ? 'btn btnGreenFill plan-sub-btn'
                : 'btn btnGreenFillLight plan-sub-btn'
            }
            // onClick={() => window.open("https://www.hollydesk.com/create")}
            onClick={() => navigate('/book-a-demo')}
          >
            {t('Get Started')}
          </button>
        )}
        <div
          className="most-popular"
          style={{ display: props.plan.isBest ? 'flex' : 'none' }}
        >
          <ImFire /> &nbsp;{' '}
          <p id={`PlanCard-mostPopular-${props.id}`}>{t('Most Popular')}</p>
        </div>
      </Zoom>
    </div>
  );
};

export default PricingCard;
