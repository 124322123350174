import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import message from 'antd/lib/message';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import en from 'world_countries_lists/data/en/world.json';
import api from '../../../../api';

import 'antd-country-phone-input/dist/index.css';
import 'flagpack/dist/flagpack.css';

const DemoForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [mobile, setMobile] = useState({ short: 'EG', phone: '' });
  const [phoneErr, setPhoneErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const bookDemoRequest = (data) => {
    setLoading(true);
    api
      .post('/admin/demo-request', data)
      .then((res) => {
        if (res.data.is_success) {
          navigate('/book-a-demo-success');
          reset();
          setMobile({ short: 'EG', phone: '' });
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  const onSubmit = (data) => {
    console.log(mobile);
    let correctedMobile;
    return Object.keys(mobile).length !== 0 &&
      mobile.short === 'EG' &&
      mobile.phone.length === 10
      ? ((correctedMobile = mobile.phone),
        (data.contactNumber = mobile.code + correctedMobile),
        bookDemoRequest(data))
      : // This case has no meaning BUT added according to the tester opnion
      Object.keys(mobile).length !== 0 &&
        mobile.short === 'EG' &&
        mobile.phone.length === 11
      ? ((correctedMobile = mobile.phone.substring(1)),
        (data.contactNumber = mobile.code + correctedMobile),
        bookDemoRequest(data))
      : (setPhoneErr(true), message.error('WRONG MOBILE'));
  };

  return (
    <section className="mainPage section-referral-form">
      <h1
        className="section-title referral-form-title"
        id="referral-form-title"
      >
        {t('demoFormLine')}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="demo-form">
        <div className="form-line-full">
          <div className="form-line-half">
            <div className="form-element-half">
              <label id="first-name-label">{t('First name')}</label>
              <input
                className="ant-input"
                id="first-name-input"
                pattern="[a-zA-Zء-ي\s]+"
                title="Only letters are allowed"
                placeholder={t('Ex: Mohamed')}
                {...register('firstName', { required: true })}
              />
              {errors.firstName && (
                <span className="form-err">First name is required</span>
              )}
            </div>
            <div className="form-element-half">
              <label id="last-name-label">{t('Last name')}</label>
              <input
                className="ant-input"
                id="last-name-input"
                pattern="[a-zA-Zء-ي\s]+"
                title="Only letters are allowed"
                placeholder={t('Ex: Mohamed')}
                {...register('lastName', { required: true })}
              />
              {errors.lastName && (
                <span className="form-err">Last name is required</span>
              )}
            </div>
          </div>
          <div className="form-element-half">
            <label id="email-label">{t('Business Email')}</label>
            <input
              className="ant-input"
              id="email-input"
              type="text"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              title="Email format should be like sometext@website.domain"
              placeholder={t('Ex: someone@hollydesk.com')}
              {...register('email', { required: true })}
            />
            {errors.email && (
              <span className="form-err">Email is required</span>
            )}
          </div>
        </div>
        <div className="form-line-full">
          <div className="form-element-half">
            <label id="team-label">{t('How Large is your Team')}</label>
            <input
              className="ant-input"
              id="team-input"
              type="number"
              min="1"
              step="1"
              title="Only numbers are allowed"
              placeholder={t('Ex: 500')}
              {...register('companySize', { required: true })}
            />
            {errors.companySize && (
              <span className="form-err">Team size is required</span>
            )}
          </div>
          <div className="form-element-half">
            <label id="role-label">{t('Your role at the company')}</label>
            <input
              className="ant-input"
              id="role-input"
              pattern="[a-zA-Zء-ي\s]+"
              title="Only letters are allowed"
              placeholder={t('Ex: CEO')}
              {...register('role', { required: true })}
            />
            {errors.role && (
              <span className="form-err">Your role is required</span>
            )}
          </div>
        </div>
        <div className="form-line-full">
          <div className="form-element-half">
            <label id="mobile-label">{t('Mobile Number')}</label>
            <ConfigProvider
              locale={en}
              areaMapper={(area) => {
                return {
                  ...area,
                  emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
                };
              }}
            >
              <CountryPhoneInput
                defaultValue={{ short: 'EG', phone: '' }}
                id="mobile-input"
                type="number"
                placeholder="1234567890"
                value={mobile}
                onChange={(v) => {
                  setPhoneErr(false);
                  setMobile(v);
                }}
              />
            </ConfigProvider>
            {phoneErr && (
              <span className="form-err">Enter a valid Mobile number</span>
            )}
          </div>
          <div className="form-element-half">
            <label id="spend-label">
              {t('How much do you think you’ll spend monthly at Hollydesk!')}
            </label>
            <select
              className="ant-input"
              id="spend-input"
              type="email"
              placeholder={t('Ex: someone@hollydesk.com')}
              {...register('spend', { required: true })}
            >
              <option selected="selected">1000 - 5000 USD</option>
              <option>5000 - 20,000 USD</option>
              <option>20,000 - 30,000 USD</option>
              <option>30,000 - 100,000 USD</option>
              <option>More than 100,000 USD</option>
            </select>
            {errors.spend && (
              <span className="form-err">Monthly spends is required</span>
            )}
          </div>
        </div>
        <button
          className="btn btnPrimary referral-form-btn"
          type="submit"
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : t('Request a demo')}
        </button>
      </form>
    </section>
  );
};

export default DemoForm;
