import React, { Fragment, useEffect } from "react";

import MainHeaders from "./mainHeaders/mainHeaders";
import ReferralTrustedBy from "./referralTrustedBy/referralTrustedBy";
import ReferralSteps from "./referralSteps/referralSteps";
import ReferralForm from "./referralForm/referralForm";

const Referral = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Fragment>
      <MainHeaders />
      <ReferralSteps />
      <ReferralForm />
      <ReferralTrustedBy />
    </Fragment>
  );
};

export default Referral;
