import sup1 from "../../../../assets/images/sup1.png";
import sup2 from "../../../../assets/images/sup2.png";
import sup3 from "../../../../assets/images/sup3.png";
import sup4 from "../../../../assets/images/sup4.png";

export const supplierManagement = {
  name: "Supplier management",
  discription:
    "Register suppliers, approve and pay their invoices digitally and in one place",
  content: [
    {
      keyName: "Easy registration",
      title: "Get suppliers verified",
      paragraph:
        "Easily register the supplier on the system and upload all his documents",
      photo: sup1,
    },
    {
      keyName: "Digital Payment",
      title: "Automate invoice payments",
      paragraph:
        "Collect all the supplier’s invoices that need to be paid by the finances digitally instead of collecting a large number of papers",
      photo: sup2,
    },
    {
      keyName: "Easy approval",
      title: "No more late payments",
      paragraph:
        "They Pass through an easy approval cycle until they reach the finance team to pay them instantly and on time",
      photo: sup3,
    },
    {
      keyName: "Track and export invoices",
      title: "View Suppliers insights",
      paragraph:
        "You can automatically track and export all supplier’s invoices easily, you’ll never have to dig or ask finance teams for details about an invoice again. You can easily view suppliers’ insights through your dashboard and simply export their data with all the payment details",
      photo: sup4,
    },
  ],
};
