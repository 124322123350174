import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import message from "antd/lib/message";

import en from "world_countries_lists/data/en/world.json";
import api from "../../../../../api";

import "./referralForm.css";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";

const ReferralForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [mobile, setMobile] = useState({ short: "EG", phone: "" });
  const [phoneErr, setPhoneErr] = useState(false);
  const [block, setBlock] = useState(false);
  const [done, setDone] = useState(false);

  const { t } = useTranslation();

  const referralRequest = (data) => {
    setBlock(true);
    api
      .post("/admin/referral", data)
      .then((res) => {
        setDone(true);
        setTimeout(() => {
          setBlock(false);
          setDone(false);
        }, 5000);
        reset();
        setMobile({ short: "EG", phone: "" });
      })
      .catch((err) => {
        message.error("Failed sending referral!");
        setBlock(false);
      });
  };

  const onSubmit = (data) => {
    let correctedMobile;
    return Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
      ? ((correctedMobile = mobile.phone),
        (data.mobileNumber = mobile.code + correctedMobile),
        referralRequest(data))
      : // This case has no meaning BUT added according to the tester opnion
      Object.keys(mobile).length !== 0 &&
        mobile.short === "EG" &&
        mobile.phone.length === 11
      ? ((correctedMobile = mobile.phone.substring(1)),
        (data.mobileNumber = mobile.code + correctedMobile),
        referralRequest(data))
      : (setPhoneErr(true), message.error("WRONG MOBILE"));
  };

  return (
    <section className="mainPage section-referral-form">
      <h1
        className="section-title referral-form-title"
        id="referral-form-title"
      >
        {t("fill company information")}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="referral-form">
        <div className="form-line-full">
          <div className="form-element-half">
            <label id="name-label">{t("First name")}</label>
            <input
              className="ant-input"
              id="name-input"
              pattern="[a-zA-Zء-ي\s]+"
              title="Only letters are allowed"
              placeholder={t("Ex: Mohamed")}
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <span className="form-err">First name is required</span>
            )}
          </div>
          <div className="form-element-half">
            <label id="name-label">{t("Last name")}</label>
            <input
              className="ant-input"
              id="name-input"
              pattern="[a-zA-Zء-ي\s]+"
              title="Only letters are allowed"
              placeholder={t("Ex: Mohamed")}
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <span className="form-err">Last name is required</span>
            )}
          </div>
        </div>
        <div className="form-line-full">
          <div className="form-element-half">
            <label id="mobile-label">{t("Mobile Number")}</label>
            <ConfigProvider
              locale={en}
              areaMapper={(area) => {
                return {
                  ...area,
                  emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
                };
              }}
            >
              <CountryPhoneInput
                defaultValue={{ short: "EG" }}
                id="mobile-input"
                type="number"
                placeholder="1234567890"
                value={mobile}
                onChange={(v) => {
                  setPhoneErr(false);
                  setMobile(v);
                }}
              />
            </ConfigProvider>
            {phoneErr && (
              <span className="form-err">Enter a valid Mobile number</span>
            )}
          </div>
          <div className="form-element-half">
            <label id="email-label">{t("Email")}</label>
            <input
              className="ant-input"
              id="email-input"
              type="text"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              title="Email format should be like sometext@website.domain"
              placeholder={t("Ex: someone@hollydesk.com")}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="form-err">Email is required</span>
            )}
          </div>
        </div>
        <div className="form-line-full">
          <div className="form-element-half">
            <label id="company-name-label">{t("Company Name")}</label>
            <input
              className="ant-input"
              id="company-name-input"
              placeholder={t("Ex: Hollydesk")}
              {...register("companyName", { required: true })}
            />
            {errors.companyName && (
              <span className="form-err">Company Name is required</span>
            )}
          </div>
          <div className="form-element-half">
            <label id="email-label">{t("Promo Code")}</label>
            <input
              className="ant-input"
              id="promo-input"
              type="text"
              placeholder={t("Promo Code")}
              {...register("promoCode", { required: false })}
            />
          </div>
        </div>

        {done ? (
          <button
            className="btn btnPrimary referral-form-btn"
            type="button"
            disabled={block}
          >
            {t("Referral Sent")} 🎉
          </button>
        ) : (
          <button
            className="btn btnPrimary referral-form-btn"
            type="submit"
            disabled={block}
          >
            {t("Send Referral")}
          </button>
        )}
      </form>
    </section>
  );
};

export default ReferralForm;
