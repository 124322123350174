import React from "react";
import { Helmet } from "react-helmet";

const HelmetMetaData = (props) => {
  const { currentArticle, shareUrl } = props;

  return (
    <Helmet>
      <title>Hollydesk</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="article" />
      <meta property="url" content={shareUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/logo192.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={currentArticle?.title} />
      <meta property="image" content={currentArticle?.coverPhoto?.path} />
      <meta
        property="quote"
        content={
          currentArticle?.description
            ? currentArticle?.description
            : "Hollydesk is a smart, modern and simplified way to take control over your business spending."
        }
      />
      <meta
        name="description"
        content={
          currentArticle?.description
            ? currentArticle.description
            : "Hollydesk is a smart, modern and simplified way to take control over your business spending."
        }
      />
      <meta name="twitter:card" content="summary"></meta>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={currentArticle?.title} />
      <meta property="og:image" content={currentArticle?.coverPhoto?.path} />
      <meta
        property="og:quote"
        content={
          currentArticle?.description
            ? currentArticle?.description
            : "Hollydesk is a smart, modern and simplified way to take control over your business spending."
        }
      />
      <meta property="og:image:type" content="image/*" />
      <meta property="og:url" content={shareUrl} />
      <meta property="og:site_name" content="Hollydesk" />
      <meta
        property="og:description"
        content={
          currentArticle?.description
            ? currentArticle.description
            : "Hollydesk is a smart, modern and simplified way to take control over your business spending."
        }
      />
    </Helmet>
  );
};

export default HelmetMetaData;
