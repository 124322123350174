import React, { Fragment, useEffect } from "react";

import DemoBanner from "./demoBanner/demoBanner";
import Benefits from "./benifits/benefits";
import DemoForm from "./demoForm/demoForm";
import LowerBanner from "../home/lowerBanner/lowerBanner";

const BookDemo = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Fragment>
      <DemoBanner />
      <Benefits />
      <DemoForm />
      <LowerBanner />
    </Fragment>
  );
};

export default BookDemo;
