import KhaledMostafa from "../../../../assets/images/Khaled Mostafa.jpeg";
import MustafaAbdellatif from "../../../../assets/images/Mustafa Abdellatif.jpg";

export const reviews = [
  {
    review:
      "As a senior accountant I used to have a lot of difficulties with finances, we were working remotely most of the time and our employees were paying from their personal money, and they had to tell me how much they paid and I had to transfer that amount with their payroll. Now Hollydesk saved us from all these problems, I no longer have to deal with cash and our employees are no longer worried about paying out of pocket as they know that the money will be instantly transferred to them after each payment with just a couple of clicks. And what I liked the most about Hollydesk is whenever I face a problem, there’s someone who calls me and instantly helps me with any issue.",
    author: "Khaled Mostafa",
    job: "Senior Accountant at Gameball",
    picture: KhaledMostafa,
  },
  {
    review:
      "While using Hollydesk, we saved a lot of time that was being wasted especially with our banking system and money transfer. And this was because of how fast and easy we were using Hollydesk, and mainly when uploading receipts and making deposits, approvals were easily made and the money was transferred instantly in just seconds. In addition to the rapid and continuous follow-up from Hollydesk’s customer support with our team.",
    author: "Mustafa Abdellatif",
    job: "CEO EYouth",
    picture: MustafaAbdellatif,
  },
  {
    review:
      "A very flexible and smart program that is very useful for companies, especially as it works within the framework of the new monetary policy for Egypt to reduce monetary transactions and deal through electronic channels and means",
    author: "Abdoallah Mohsen",
    job: "Cost accountant",
    picture: "",
  },
  {
    review:
      "Ease of use financial management platform, flexible and easy to use and very useful platform, UI & UX are great through dashboard & app but need more improvent, help employees in submitting work-related invoiced and getting paid in no time, while keeping organization financial records clean",
    author: "Ahmed Sannour",
    job: "Independent Affiliate",
    picture: "",
  },
  {
    review:
      "Perfect cost saver for companies which have many remote sites and centralized Finance Management. Also it's rescuing the wasted time in paperworks for petty cash requests etc..",
    author: "Mostafa El shafey",
    job: "Financial Accountant",
    picture: "",
  },
];
