import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

import { expenseReimbursements } from "../featuresContent/expenseReimbursements";
import { inAdvancePayment } from "../featuresContent/inAdvancePayment";
import { supplierManagement } from "../featuresContent/supplierManagement";
import { reporting } from "../featuresContent/reporting";
import { businessCredit } from "../featuresContent/businessCredit";

import "./featureElements.css";

const FeatureElements = () => {
  const [feature, setFeature] = useState({});

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const url = window.location.pathname;

  useEffect(() => {
    switch (url) {
      case "/features/expense-reimbursement":
        return setFeature(expenseReimbursements);
      case "/features/in-advance-payment":
        return setFeature(inAdvancePayment);
      case "/features/supplier-management":
        return setFeature(supplierManagement);
      case "/features/reporting":
        return setFeature(reporting);
      case "/features/business-credit":
        return setFeature(businessCredit);
      default:
        return setFeature(expenseReimbursements);
    }
  }, [url]);

  useEffect(() => window.scrollTo(0, 0), [url]);

  return (
    <section className="app-features-wrapper">
      <div className="mainPage app-features-section">
        <h1
          className="section-title app-features-title"
          id="app-features-title"
        >
          {t(feature.name)}
        </h1>
        <p className="app-features-disc" id="app-features-disc">
          {t(feature.discription)}
        </p>
        {feature?.content?.map((el, i) => {
          return (
            <Fade direction="up" delay={30} triggerOnce cascade key={i}>
              <div
                className="app-feature-element"
                key={i + 1}
                style={{
                  flexDirection: i & 1 ? "row" : "row-reverse",
                  justifyContent: i & 1 ? "flex-start" : "flex-end",
                  background:
                    lang === "en"
                      ? i & 1
                        ? "linear-gradient(90deg, transparent 40%, #F9FAFB 60%)"
                        : "linear-gradient(90deg, #F9FAFB 60%, transparent 40%)"
                      : i & 1
                      ? "linear-gradient(90deg, #F9FAFB 60%, transparent 40%)"
                      : "linear-gradient(90deg, transparent 40%, #F9FAFB 60%)",
                }}
              >
                <img
                  src={el.photo}
                  alt={el.name}
                  id={`app-features-image-${i + 1}`}
                />
                <div className="app-feature-element-dialog">
                  <p
                    className="app-feature-element-name"
                    id={`app-features-element-name-${i + 1}`}
                  >
                    {t(el.keyName)}
                  </p>
                  <h3
                    className="app-feature-element-title"
                    id={`app-features-element-title-${i + 1}`}
                  >
                    {t(el.title)}
                  </h3>
                  <p
                    className="app-feature-element-content"
                    id={`app-features-element-content-${i + 1}`}
                  >
                    {t(el.paragraph)}
                  </p>
                </div>
              </div>
            </Fade>
          );
        })}
      </div>
    </section>
  );
};

export default FeatureElements;
