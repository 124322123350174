import React from "react";
import { useTranslation } from "react-i18next";

import video from "../../../../assets/videos/hollydesk1.6.mp4";
import poster from "../../../../assets/images/video-poster.png";

import "./videoSection.css";

const VideoSection = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage videoSection">
      <h1 className="section-title videoSection-title" id="videoSection-title">
        {t("How it works")}
      </h1>
      <video
        className="video-frame"
        controls
        poster={poster}
        id="howItWorks-Video"
      >
        <source src={video} type="video/mp4" />
      </video>
    </section>
  );
};

export default VideoSection;
