import rem1 from "../../../../assets/images/rem1.png";
import rem2 from "../../../../assets/images/rem2.png";
import rem3 from "../../../../assets/images/rem3.png";
import rem4 from "../../../../assets/images/rem4.png";

export const expenseReimbursements = {
  name: "Expense Reimbursements",
  discription:
    "Reimburse your employees for out-of-pocket expenses quickly and in a breeze",
  content: [
    {
      keyName: "Anytime on the go",
      title: "A smarter way to do employee expenses",
      paragraph:
        "The employee simply takes a picture of any receipt he receives using his mobile phone anytime on the go, uploads it on Hollydesk, and then it’s automatically delivered to the manager at the same moment",
      photo: rem1,
    },
    {
      keyName: "With just a click",
      title: "Get them approved in no time",
      paragraph:
        "Instead of collecting and doing too much paperwork you can easily validate and pay all the company expenses with a click",
      photo: rem2,
    },
    {
      keyName: "Withdraw money easily",
      title: "Expense payments are done easily",
      paragraph:
        "The finance team automatically pays the employee once he gets the approval and the money can be withdrawn from any digital wallet or bank account",
      photo: rem3,
    },
    {
      keyName: "Easey tracking",
      title: "Track expenses",
      paragraph:
        "The employees and managers can keep track of their expenses from their dashboards",
      photo: rem4,
    },
  ],
};
