import React from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

import group1 from "../../../../assets/images/Group 1.png";
import group2 from "../../../../assets/images/Group 2.png";

import "./benefits.css";

const Benefits = () => {
  const features = [
    {
      title: "Automated receipt approval and tracking",
      content:
        "Receipts and invoices are auto scanned and forwarded automatically for digital approval and payment.",
      image: group1,
    },
    {
      title: "Real-time reporting",
      content:
        "Reports are automatically made in real-time, and you can access them anytime. No more errors caused by manual data entry.",
      image: group2,
    },
    {
      title: "Built-in wallet",
      content:
        "charge your digital wallet to pay your employees and suppliers easily and keep track of payments and budgets.",
      image: group1,
    },
  ];

  const { t } = useTranslation();

  return (
    <section className="mainPage benefitsSection">
      <h1 className="section-title benefits-title" id="benefits-title">
        {t("demoLine1")}
      </h1>
      {features.length === 0
        ? ""
        : features.map((el, i) => {
            return (
              <Fade direction="up" delay={30} triggerOnce>
                <div
                  className="feature-element"
                  key={i + 1}
                  style={{
                    flexDirection: i & 1 ? "row" : "row-reverse",
                    justifyContent: i & 1 ? "flex-start" : "flex-end",
                    backgroundColor: i & 1 ? "transparent" : "#f2f8fb",
                  }}
                >
                  <img
                    src={el.image}
                    alt={el.name}
                    id={`feature-element-img-${i + 1}`}
                  />
                  <div className="feature-element-dialog">
                    <h3 id={`feature-element-title${i + 1}`}>{t(el.title)}</h3>
                    <p
                      className="feature-element-content"
                      id={`feature-element-content${i + 1}`}
                    >
                      {t(el.content)}
                    </p>
                  </div>
                </div>
              </Fade>
            );
          })}
    </section>
  );
};

export default Benefits;
