import { combineReducers } from "redux";

import plansReducer from "./plansReducer";
import blogReducer from "./blogReducer";

const rootReducer = combineReducers({
  plans: plansReducer,
  blog: blogReducer,
});

export default rootReducer;
