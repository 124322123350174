import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/lib/tabs';
import { useDispatch, useSelector } from 'react-redux';

import ArticleBanner from './articleBanner/articleBanner';
import ArticleCard from './articleCard/articleCard';
import BlogForm from './blogForm/blogForm';
import { getArticles } from '../../../../store/actions/blogActions';

import './blog.css';

const Blog = () => {
  const articles = useSelector((state) => state.blog.articles);
  const [bannerArticle, setBannerArticle] = useState([]);
  const [remainArticles, setRemainArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { TabPane } = Tabs;
  useEffect(() => {
    dispatch(getArticles());
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBannerArticle(articles[0]);
    setRemainArticles(articles);
  }, [articles]);

  const filterArticles = (key) => {
    switch (key) {
      case '2':
        const result2 = remainArticles.filter(
          (art) =>
            art.category !== 'Announcement' &&
            art.category !== 'Event' &&
            art.category !== 'Customer stories'
        );
        setFilteredArticles(result2);
        break;
      case '3':
        const result3 = remainArticles.filter(
          (art) => art.category === 'Announcement'
        );
        setFilteredArticles(result3);
        console.log(result3);
        break;
      case '4':
        const result4 = remainArticles.filter(
          (art) => art.category === 'Event'
        );
        setFilteredArticles(result4);
        break;
      case '5':
        const result5 = remainArticles.filter(
          (art) => art.category === 'Customer stories'
        );
        setFilteredArticles(result5);
        break;

      default:
        break;
    }
  };

  return (
    <section className="mainPage blog-section">
      <ArticleBanner article={bannerArticle} showBtn={true} />
      <Tabs defaultActiveKey="1" onChange={filterArticles} size="large">
        <TabPane tab={t('All')} key="1">
          <div className="article-cards-container">
            {remainArticles?.map((art, i) => {
              return <ArticleCard article={art} key={i} />;
            })}
          </div>
        </TabPane>
        <TabPane tab={t('Articles')} key="2">
          <div className="article-cards-container">
            {filteredArticles.map((art, i) => {
              return <ArticleCard article={art} key={i} />;
            })}
          </div>
        </TabPane>
        <TabPane tab={t('Announcements')} key="3">
          <div className="article-cards-container">
            {filteredArticles.map((art, i) => {
              return <ArticleCard article={art} key={i} />;
            })}
          </div>
        </TabPane>
        <TabPane tab={t('Events')} key="4">
          <div className="article-cards-container">
            {filteredArticles.map((art, i) => {
              return <ArticleCard article={art} key={i} />;
            })}
          </div>
        </TabPane>
        <TabPane tab={t('Customer Stories')} key="5">
          <div className="article-cards-container">
            {filteredArticles.map((art, i) => {
              return <ArticleCard article={art} key={i} />;
            })}
          </div>
        </TabPane>
      </Tabs>
      <BlogForm />
    </section>
  );
};

export default Blog;
