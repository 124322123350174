export const FAQs = [
  {
    q: "pricingQuestion-1",
    a: "pricingAnswer-1",
  },
  {
    q: "pricingQuestion-2",
    a: "pricingAnswer-2",
  },
  {
    q: "pricingQuestion-3",
    a: "pricingAnswer-3",
  },
  {
    q: "pricingQuestion-4",
    a: "pricingAnswer-4",
  },
  {
    q: "pricingQuestion-5",
    a: "pricingAnswer-5",
  },
];
