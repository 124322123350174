import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Drawer from "antd/lib/drawer";
import { GiHamburgerMenu } from "react-icons/gi";

import FeaturesMenu from "./featuresMenu";
import ResourcesMenu from "./resourcesMenu";
import DrawerMenu from "./drawerMenu/drawerMenu";

import logo from "../../../assets/images/hollydesk-logo.png";
import egFlag from "../../../assets/images/egypt.png";
import usFlag from "../../../assets/images/united-states.png";

import "./navBar.css";

const NavBar = () => {
  const [langIndex, setLangIndex] = useState(0);
  const [visible, setVisible] = useState(false);

  const { t, i18n } = useTranslation();

  const langChangeHandler = (lang) => i18n.changeLanguage(lang);

  const lang = i18n.language;

  useEffect(() => {
    lang === "ar" ? setLangIndex(1) : setLangIndex(0);
  }, [lang]);

  const languages = [
    {
      lang: "EN",
      code: "en",
      flag: usFlag,
      id: 0,
    },
    {
      lang: "AR",
      code: "ar",
      flag: egFlag,
      id: 1,
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const menu = (
    <Menu>
      {languages.map((el) => {
        return (
          <Menu.Item
            key={el.id}
            onClick={() => {
              langChangeHandler(el.code);
              setLangIndex(el.id);
            }}
          >
            <div className="lang-option">
              <img src={el.flag} alt={el.lang} />
              <span className="lang-name">{el.lang}</span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className="mainPage nav-bar">
      <nav>
        <img className="nav-bar-logo" src={logo} alt="logo" id="mainNav-logo" />
        <div className="nav-bar-items">
          <NavLink
            to="/"
            end
            className={(nav) =>
              nav.isActive ? "nav-bar-item-active" : "nav-bar-item"
            }
            id="mainNav-link-home"
          >
            {t("home")}
          </NavLink>
          <NavLink
            to="/howItWorks"
            end
            className={(nav) =>
              nav.isActive ? "nav-bar-item-active" : "nav-bar-item"
            }
            id="mainNav-link-howItWorks"
          >
            {t("How it works")}
          </NavLink>
          <FeaturesMenu />
          <NavLink
            to="/pricing"
            end
            className={(nav) =>
              nav.isActive ? "nav-bar-item-active" : "nav-bar-item"
            }
            id="mainNav-link-pricing"
          >
            {t("Pricing")}
          </NavLink>
          <ResourcesMenu />
        </div>
      </nav>
      <div className="nav-bar-actions">
        <Dropdown overlay={menu} trigger={["click"]}>
          <div
            className="lang-option nav-bar-item"
            id="mainNav-link-changeLang"
          >
            <img
              src={languages[langIndex].flag}
              alt={languages[langIndex].lang}
              id={`lang-${languages[langIndex].lang}-img`}
            />
            <span
              className="lang-name"
              id={`lang-${languages[langIndex].lang}`}
            >
              {languages[langIndex].lang}
            </span>
            <DownOutlined className="lang-icon" />
          </div>
        </Dropdown>
        {/*<NavLink
          className={(nav) =>
            nav.isActive ? "nav-bar-item-active" : "nav-bar-item"
          }
          to="/login"
          id={`login-link`}
        >
          {t("Login")}
        </NavLink>*/}
        <a className="nav-bar-item" href="https://www.app.hollydesk.com/login">
          {t("Login")}
        </a>
        <Link
          to="/book-a-demo"
          className="btn btnGreenFill"
          id="mainNav-demoBooking"
        >
          {t("Book a demo")}
        </Link>
      </div>
      <div className="mobile-menu">
        <GiHamburgerMenu onClick={showDrawer} className="nav-action" />
        <Drawer
          title={<img alt="logo" src={logo} />}
          onClose={onClose}
          visible={visible}
        >
          <DrawerMenu closeHandler={onClose} />
        </Drawer>
      </div>
    </div>
  );
};

export default NavBar;
