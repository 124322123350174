import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./secondBanner.css";

const SecondBanner = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage secondBannerSection">
      <h1 className="section-title secondBanner-title" id="secondBanner-title">
        {t("Many of our users say that Hollydesk has helped them")}{" "}
        <span className="landingPage-bannerBackGround">
          {t("save 9 working days")}
        </span>{" "}
        {t("each month")}
      </h1>
      <div className="lowerBanner-buttons">
        <Link to="/book-a-demo" className="btn btnPrimary" id="book-a-demo-btn">
          {t("Book a demo")}
        </Link>
        <a
          href="https://www.hollydesk.com/create"
          target="_blank"
          rel="noreferrer"
          className="btn btnSecondary"
          id="try-it-out-btn"
          style={{ color: "#1D2B36" }}
        >
          {t("Try it out")}
        </a>
      </div>
    </section>
  );
};

export default SecondBanner;
