import axios from 'axios';
require('dotenv').config();

// export default axios.create({
//   baseURL: 'http://localhost:4000',
// });

export default axios.create({
  baseURL: process.env.REACT_APP_API,
});
