import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';
import { useNavigate, useLocation } from 'react-router-dom';

import MetaData from './metaData';
import LandingPage from './components/landingPage/landingPage';
import Login from './components/landingPage/login/login';
import Home from './components/landingPage/home/home';
import HowItWorks from './components/landingPage/howItWorks/howItWorks';
import Pricing from './components/landingPage/pricing/pricing';
import Features from './components/landingPage/features/features';
import FeatureElements from './components/landingPage/features/featureElements/featureElements';
import Resources from './components/landingPage/resources/resources';
import Blog from './components/landingPage/resources/blog/blog';
import ArticlePage from './components/landingPage/resources/blog/articlePage/articlePage';
import FAQs from './components/landingPage/resources/FAQs/FAQs';
import Calculator from './components/landingPage/resources/calculator/calculator';
import Referral from './components/landingPage/resources/referral/referral';
import BookDemo from './components/landingPage/bookDemo/bookDemo';
import SuccessDemo from './components/landingPage/bookDemo/success/success';
import Reviews from './components/landingPage/reviews/reviews';
import './App.css';
import './graphikFontFamily.css';
import './components/landingPage/landingPage-mediaQueries.css';
import 'antd/dist/antd.css';
import Privecy from './components/landingPage/PrivecyAndPolcie';

function App() {
  const [dir, setDir] = useState('ltr');

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    location.pathname === '/' && navigate('/home');
  }, [location]);

  useEffect(() => {
    lang === 'ar' ? setDir('rtl') : setDir('ltr');
  }, [lang]);

  // DISABLE MOUSE WHEEL CHANGE NUMBER INPUT VALUE:
  document.addEventListener('wheel', function (event) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

  const INTERCOM_APP_ID = 't9p3f0de';

  return (
    <div
      className="App"
      style={{
        direction: dir,
        fontFamily: lang === 'ar' ? 'Almarai' : 'Graphik',
      }}
    >
      {/* <MetaData /> */}
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <Routes>
          <Route path="/" element={<LandingPage />}>
            <Route index path="/home" element={<Home />} />
            <Route path="howItWorks" element={<HowItWorks />} />
            <Route path="features" element={<Features />}>
              <Route
                path="expense-reimbursement"
                element={<FeatureElements />}
              />
              <Route path="in-advance-payment" element={<FeatureElements />} />
              <Route path="supplier-management" element={<FeatureElements />} />
              <Route path="reporting" element={<FeatureElements />} />
              <Route path="business-credit" element={<FeatureElements />} />
            </Route>
            <Route path="pricing" element={<Pricing />} />
            <Route path="resources" element={<Resources />}>
              <Route path="blog" element={<Blog />} />
              <Route path="blog/:articleId" element={<ArticlePage />} />
              <Route path="FAQs" element={<FAQs />} />
              <Route path="calculator" element={<Calculator />} />
              <Route path="referral" element={<Referral />} />
            </Route>
            <Route path="book-a-demo" element={<BookDemo />} />
            <Route path="book-a-demo-success" element={<SuccessDemo />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="privacy-policy" element={<Privecy />} />
          </Route>
        </Routes>
      </IntercomProvider>
    </div>
  );
}

export default App;
