import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";

import PricingOptions from "./pricingOptions/pricingOptions";
import PricingFAQ from "./pricingFAQ/pricingFAQ";
import KnowMore from "./knowMore/knowMore";
import LowerBanner from "./lowerBanner/lowerBanner";

const Pricing = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div>
      <PricingOptions />
      <PricingFAQ />
      <Fade direction="up" delay={30} triggerOnce>
        <KnowMore />
      </Fade>
      <LowerBanner />
    </div>
  );
};

export default Pricing;
