import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import seccessIMG from "../../../../assets/images/SuccessDemo.png";

import "./success.css";

const SuccessDemo = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <section className="mainPage seccess-demo">
      <img
        src={seccessIMG}
        alt="seccessIMG"
        id="seccessIMG"
        className="seccessIMG"
      />
      <h1 className="section-title">
        {t("Congratulations 🎉 , you're all set up")}
      </h1>
      <p className="sucess-msg">
        {t(
          "we received your information and it is being processed and we'll contact you as soon as possible"
        )}
      </p>
      <button
        className="btn btnPrimary seccessBtn"
        onClick={() => navigate("/")}
      >
        {t("Back to Home")}
      </button>
    </section>
  );
};

export default SuccessDemo;
