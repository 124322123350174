import MustafaAbdellatif from "../../../../assets/images/Mustafa Abdellatif.jpg";
import MohamedAboRomana from "../../../../assets/images/Mohamed Abo Romana.jpeg";
import YoussefDarwish from "../../../../assets/images/Youssef Darwish.jpeg";
import MohamedAkmal from "../../../../assets/images/Mohamed Akmal.jpeg";
import KhaledMostafa from "../../../../assets/images/Khaled Mostafa.jpeg";

export const reviews = [
  {
    review:
      "A very flexible and smart program that is very useful for companies, especially as it works within the framework of the new monetary policy for Egypt to reduce monetary transactions and deal through electronic channels and means",
    author: "Abdoallah Mohsen",
    job: "Cost accountant",
    picture: "",
  },
  {
    review:
      "Ease of use financial management platform, flexible and easy to use and very useful platform, UI & UX are great through dashboard & app but need more improvent, help employees in submitting work-related invoiced and getting paid in no time, while keeping organization financial records clean",
    author: "Ahmed Sannour",
    job: "Independent Affiliate",
    picture: "",
  },
  {
    review:
      "Perfect cost saver for companies which have many remote sites and centralized Finance Management. Also it's rescuing the wasted time in paperworks for petty cash requests etc..",
    author: "Mostafa El shafey",
    job: "Financial Accountant",
    picture: "",
  },
  {
    review:
      "While using Hollydesk, we saved a lot of time that was being wasted especially with our banking system and money transfer. And this was because of how fast and easy we were using Hollydesk, and mainly when uploading receipts and making deposits, approvals were easily made and the money was transferred instantly in just seconds. In addition to the rapid and continuous follow-up from Hollydesk’s customer support with our team.",
    author: "Mustafa Abdellatif",
    job: "CEO EYouth",
    picture: MustafaAbdellatif,
  },
  {
    review:
      "Hollydesk made all the payments easier, without any fears of petty cash issues,  and with a couple of clicks you can reimburse your employees and they can get their money back instantly.",
    author: "Mohamed Abo Romana",
    job: "CEO Kart",
    picture: MohamedAboRomana,
  },
  {
    review:
      "The customer support is really magnificent, any reported issue is solved instantly, the service is so fast and unique, you can manage all transactions from one place. You can transfer money to any bank account or mobile wallet with just a click, we also have no cash limits. Before Hollydesk, I used to use three or four different accounts as I reached the maximum limit everytime.Finally, the development curve in Hollydesk is exponential. I am astonished how a startup could grow and develop its service in such an incredible way.",
    author: "Youssef Darwish",
    job: "CEO Supernova",
    picture: YoussefDarwish,
  },
  {
    review:
      "Hollydesk’s credit funding feature helped us a lot when during our crisis with the bank, we really needed that money. It also helped us with the payroll, as dealing with the bank was very challenging for many of us, we wanted to send payroll to our personal bank account or digital wallet. And finally, because of Hollydesk’s easy system, the tracking of the month-end closing, it’s no longer a hassle.",
    author: "Mohamed Akmal",
    job: "Co-founder Career 180",
    picture: MohamedAkmal,
  },
  {
    review:
      "As a senior accountant I used to have a lot of difficulties with finances, we were working remotely most of the time and our employees were paying from their personal money, and they had to tell me how much they paid and I had to transfer that amount with their payroll. Now Hollydesk saved us from all these problems, I no longer have to deal with cash and our employees are no longer worried about paying out of pocket as they know that the money will be instantly transferred to them after each payment with just a couple of clicks. And what I liked the most about Hollydesk is whenever I face a problem, there’s someone who calls me and instantly helps me with any issue.",
    author: "Khaled Mostafa",
    job: "Senior Accountant at Gameball",
    picture: KhaledMostafa,
  },
];
