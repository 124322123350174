import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from '../../../../store/actions/plansActions';

import PricingCard from '../pricingCard/pricingCard';

import './pricingOptions.css';

const PricingOptions = () => {
  const plans = useSelector((state) => state.plans.plans);
  // const plans = [];
  const [currency, setCurrency] = useState('');

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    // getPlans('Egypt');
    // setCurrency('EGP');

    console.log(plans);
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=1e43f30ff2ef4b2096c91ab4465270f4',
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setCurrency(result.country.currency === 'EGP' ? 'EGP' : 'USD');
        dispatch(getPlans(result.country.name === 'Egypt' ? 'Egypt' : 'Any'));
      })
      .catch((error) => console.log('error', error));
    // eslint-disable-next-line
  }, []);

  return (
    <section className="mainPage pricingSection">
      <h5 className="pricingSection-disc" id="pricingSection-disc">
        {t('Enjoy the ease of your business spending')}
      </h5>
      <h1
        className="section-title pricingSection-title"
        id="pricingSection-title"
      >
        {t('Select the most appropriate solution that best empowers your team')}
      </h1>
      <div className="plan-cards-container">
        {plans.map((plan, i) => {
          return (
            <PricingCard
              plan={plan}
              currency={currency}
              key={plan.id}
              index={i}
              id={`BillingPlan-Title-${i + 10}`}
            />
          );
        })}
      </div>
    </section>
  );
};

export default PricingOptions;
