import { GET_ARTICLES } from "./actionTypes";

import api from "../../api";

export const getArticles = () => (dispatch) => {
  api
    .get("/admin/blogs")
    .then((res) => {
      dispatch({
        type: GET_ARTICLES,
        articles: res.data.info,
      });
    })
    .catch((err) => console.log(err));
};
