import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import landingBanner from '../../../../assets/images/landing1.png';
import landingBannerAr from '../../../../assets/images/landing1-AR.png';
import { FcCheckmark } from 'react-icons/fc';

import './mainBanner.css';

const MainBanner = () => {
  const benifits = [
    '0% transfer fees',
    'No credit card required',
    'No setup fees',
  ];

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const navigate = useNavigate();

  return (
    <section className="mainPage landingPage-banner">
      <div className="landingPage-bannerContent">
        <h1
          className="landingPage-bannerHeadLine"
          id="landingPage-bannerHeadLine"
        >
          {t('Manage your Company’s')}
          <br />
          <span className="landingPage-bannerBackGround">
            {t('daily expenses,')}
          </span>{' '}
          {t('Anywhere In Real-Time!')}
        </h1>
        <h3
          className="landingPage-bannerSubTitle"
          id="landingPage-bannerSubTitle"
        >
          {t(
            'A very powerful platform where you can control all your company’s spendings in one place'
          )}
        </h3>
        <div className="landingPage-bannerbuttons">
          <button
            className="btn btnPrimary"
            id="book-a-demo-btn"
            onClick={() => navigate('/book-a-demo')}
          >
            {t('Book a demo')}
          </button>
          <button
            // href=
            // target="_blank"
            // rel="noreferrer"
            className="btn btnSecondary"
            id="try-it-out-btn"
            style={{ color: '#1D2B36' }}
            onClick={() => window.open('https://app.hollydesk.com/create')}
          >
            {t('Try it out')}
          </button>
        </div>
        {benifits.map((el, i) => {
          return (
            <span
              className="landingPage-benifit"
              id={`landingPage-benifit-${i + 1}`}
              key={i}
            >
              <FcCheckmark /> {t(el)}
            </span>
          );
        })}
      </div>
      <img
        src={lang === 'ar' ? landingBannerAr : landingBanner}
        alt="landing banner"
        id="landingPage-main-pic"
      />
    </section>
  );
};

export default MainBanner;
