import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Collapse from "antd/lib/collapse";
import Divider from "antd/lib/divider";

import { FAQs } from "./pricingQuestions";

import "./pricingFAQ.css";

const PricingFAQ = () => {
  const { Panel } = Collapse;

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <section className="mainPage pricingFAQSection">
      <h1
        className="section-title pricingFAQSection-title"
        id="pricingFAQSection-title"
      >
        {t("Pricing FAQs")}
      </h1>
      <div className="FAQs-group">
        <Collapse
          className="FAQ-question"
          defaultActiveKey={["0"]}
          expandIconPosition={lang === "ar" ? "left" : "right"}
          accordion
          ghost
        >
          {FAQs.map((faq, i) => {
            return (
              <Fragment key={i}>
                <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
                <Panel header={t(faq.q)} key={i}>
                  <h3 className="FAQ-answer" id="FAQ-answer">
                    {t(faq.a)}
                  </h3>
                </Panel>
              </Fragment>
            );
          })}
        </Collapse>
      </div>
      <Divider style={{ borderTop: "1px solid #9CA3AF", margin: "0px" }} />
    </section>
  );
};

export default PricingFAQ;
