import React, { useState } from "react";

import CalculationInputs from "./calculationInputs/calculationInputs";
import CalculatorScreen from "./calculatorScreen/calculatorScreen";

import "./calculator.css";

const Calculator = () => {
  const [employees, setEmployees] = useState(0);
  const [reports, setReports] = useState(1);
  const [expense, setExpense] = useState(50);
  const [budget, setBudget] = useState(3000);
  const [company, setCompany] = useState("");
  const [stage, setStage] = useState(1);

  const employeesHandler = (value) => setEmployees(value);
  const reportsHandler = (value) => setReports(value);
  const expenseHandler = (value) => setExpense(value);
  const budgetHandler = (value) => setBudget(value);
  const companyHandler = (value) => setCompany(value);
  const stageHandler = (value) => setStage(value);

  return (
    <section className="mainPage section-calculator">
      <CalculationInputs
        employeesHandler={employeesHandler}
        reportsHandler={reportsHandler}
        expenseHandler={expenseHandler}
        budgetHandler={budgetHandler}
        companyHandler={companyHandler}
        stageHandler={stageHandler}
      />
      <CalculatorScreen
        employees={employees}
        reports={reports}
        expense={expense}
        budget={budget}
        company={company}
        stage={stage}
      />
    </section>
  );
};

export default Calculator;
