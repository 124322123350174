import React from 'react';
import Avatar from 'antd/lib/avatar';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import person from '../../../../../assets/images/human.png';

import './articleCard.css';

const ArticleCard = (props) => {
  const { article } = props;

  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const color = () => {
    switch (article.category) {
      case 'Spend Management':
        return '#56C288';
      case 'Finance':
        return '#FFC555';
      case 'Accounting':
        return '#0073A7';
      case 'Supplier Management':
        return '#515A5A';
      case 'Inside Hollydesk':
        return '#DC7633';
      case 'Expense claims':
        return '#F7DC6F';
      case 'Expense Management':
        return '#A569BD';
      case 'What’s New':
        return '#0B5345';
      case 'Tools & Tips':
        return '#CD6155';
      case 'Customer stories':
        return '#89CFF0';

      default:
        return '#56C288';
    }
  };

  return (
    <div
      className="article-card"
      onClick={() =>
        navigate(`/resources/blog/${article._id}?id=${article._id}`)
      }
    >
      <img src={article.coverPhoto.path} alt="banner" />
      <span className="articleCard-label" style={{ backgroundColor: color() }}>
        {t(article.category)}
      </span>
      <p className="articleCard-title">
        {lang === 'ar' ? article.titleAr : article.title}
      </p>
      <div className="articleCard-publish">
        <div className="articleCard-author">
          <Avatar
            src={article.user.image === '' ? person : article.user.image}
            size={40}
          />
          <h3 className="articleCard-authorName">{article.user.name}</h3>
        </div>
        <p style={{ color: '#4B5563' }}>
          {dayjs(article.date).format('DD/MM/YYYY')}
        </p>
      </div>
    </div>
  );
};

export default ArticleCard;
