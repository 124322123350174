import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Avatar from "antd/lib/avatar";
import { CgQuote } from "react-icons/cg";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import Carousel from "antd/lib/carousel";
import Marquee from "react-fast-marquee";

import gameball from "../../../../assets/images/gameball-logo.png";
import zest from "../../../../assets/images/zest-logo.png";
import gizaSystems from "../../../../assets/images/giza-systems-logo.png";
import adam from "../../../../assets/images/ADAM.png";
import awtad from "../../../../assets/images/AWTAD.png";
import eyouth from "../../../../assets/images/EYOUTH.png";
import hvo from "../../../../assets/images/HVO.png";
import kaart from "../../../../assets/images/KAART.png";
import schoolarships from "../../../../assets/images/Scholarships and Awards Summit Logo.png";
import simplex from "../../../../assets/images/Simplex_Logo.png";
import supernova from "../../../../assets/images/Supernova Logo.png";
import thndr from "../../../../assets/images/THNDR.png";
import career from "../../../../assets/images/CAREER.png";
import filetto from "../../../../assets/images/Filetto.png";
import turbo from "../../../../assets/images/turbo.png";
import reviewer from "../../../../assets/images/human.png";
import { reviews } from "./reviews";

import "./trustedBy.css";

const TrustedBy = () => {
  const [index, setIndex] = useState(0);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const navigate = useNavigate();

  const carouselRef = useRef();
  const mobileCarouselRef = useRef();

  return (
    <section className="trustedBy-wrapper">
      <div className="mainPage trustedBy-section">
        <h1 className="section-title trustedBy-title" id="trustedBy-title">
          {t("Trusted By Finance")}
        </h1>
        <div className="featuredIn-logos" style={{ direction: "ltr" }}>
          <Marquee
            speed={30}
            gradientWidth={100}
            gradientColor={[243, 244, 246]}
          >
            <img src={gameball} alt="company-logo" id="company-logo-1" />
            <img src={turbo} alt="company-logo" id="company-logo-2" />
            <img src={zest} alt="company-logo" id="company-logo-3" />
            <img src={gizaSystems} alt="company-logo" id="company-logo-4" />
            <img src={adam} alt="company-logo" id="company-logo-5" />
            <img src={awtad} alt="company-logo" id="company-logo-6" />
            <img src={eyouth} alt="company-logo" id="company-logo-7" />
            <img src={hvo} alt="company-logo" id="company-logo-8" />
            <img src={kaart} alt="company-logo" id="company-logo-9" />
            <img src={schoolarships} alt="company-logo" id="company-logo-10" />
            <img src={simplex} alt="company-logo" id="company-logo-11" />
            <img src={supernova} alt="company-logo" id="company-logo-12" />
            <img src={thndr} alt="company-logo" id="company-logo-13" />
            <img src={career} alt="company-logo" id="company-logo-14" />
            <img src={filetto} alt="company-logo" id="company-logo-15" />
          </Marquee>
        </div>
        <div className="review-wrapper">
          <div className="review">
            <h1 id="review-slogan">
              {t("More than 3000 Clients, you would want to hear from them")}
            </h1>
            <p className="review-text" id="review-text-1">
              {t(
                "But dont just take our word for it, see what our customers have to say"
              )}
            </p>
            <button
              className="btn btnPrimary"
              id="read-all-reviews-btn"
              onClick={() => navigate("/reviews")}
            >
              {t("Read all reviews")}
            </button>
          </div>
          <div className="testimonials">
            <Carousel ref={carouselRef} dots={false}>
              {reviews.map((review, i) => {
                return (
                  <div key={i + "key"}>
                    <p className="review-text" id="review-text">
                      <CgQuote />
                      <span>{review.review}</span>
                      <CgQuote />
                    </p>
                    <div className="reviewer">
                      <Avatar
                        src={review.picture === "" ? reviewer : review.picture}
                        alt="reviewer"
                        size={60}
                        id="reviwer-avatar"
                      />
                      <div className="reviewer-info">
                        <h3 id="reviwer-name">{review.author}</h3>
                        <span id="reviwer-job">{review.job}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
            <span className="reviews-controls" style={{ direction: "ltr" }}>
              <GrPrevious
                className="review-control"
                id="navigate-btn-1"
                onClick={() => {
                  carouselRef.current.prev();
                  setIndex(index === 0 ? reviews.length - 1 : index - 1);
                }}
              />
              {`${index + 1} / ${reviews.length}`}
              <GrNext
                className="review-control"
                id="navigate-btn-1"
                onClick={() => {
                  carouselRef.current.next();
                  setIndex(index === reviews.length - 1 ? 0 : index + 1);
                }}
              />
            </span>
          </div>
        </div>
        <div className="review-wrapper-mobile">
          <div className="review">
            <h1 id="review-slogan">
              {t("More than 3000 Clients, you would want to hear from them")}
            </h1>
            <p className="review-text" id="review-text-1">
              {t(
                "But dont just take our word for it, see what our customers have to say"
              )}
            </p>
            <div className="testimonials">
              <Carousel ref={mobileCarouselRef} dots={false}>
                {reviews.map((review, i) => {
                  return (
                    <div key={i + "key"}>
                      <p className="review-text" id="review-text">
                        <CgQuote />
                        <span>{review.review}</span>
                        <CgQuote />
                      </p>
                      <div className="reviewer">
                        <Avatar
                          src={
                            review.picture === "" ? reviewer : review.picture
                          }
                          alt="reviewer"
                          size={60}
                          id="reviwer-avatar"
                        />
                        <div className="reviewer-info">
                          <h3 id="reviwer-name">{review.author}</h3>
                          <span id="reviwer-job">{review.job}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
              <span className="reviews-controls" style={{ direction: "ltr" }}>
                <GrPrevious
                  className="review-control"
                  id="navigate-btn-1"
                  onClick={() => {
                    mobileCarouselRef.current.prev();
                    setIndex(index === 0 ? reviews.length - 1 : index - 1);
                  }}
                />
                {`${index + 1} / ${reviews.length}`}
                <GrNext
                  className="review-control"
                  id="navigate-btn-1"
                  onClick={() => {
                    mobileCarouselRef.current.next();
                    setIndex(index === reviews.length - 1 ? 0 : index + 1);
                  }}
                />
              </span>
            </div>
            <button
              className="btn btnPrimary read-all-reviews-btn"
              id="read-all-reviews-btn"
              onClick={() => navigate("/reviews")}
            >
              {t("Read all reviews")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedBy;
