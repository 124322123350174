import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./lowerBanner.css";

const LowerBanner = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage knowMore-lowBanner">
      <h1
        className="section-title knowMore-lowBanner-title"
        id="knowMore-lowBanner-title"
      >
        {t("Is Hollydesk the right solution for your company?")}
      </h1>
      <div className="lowerBanner-buttons">
        <Link to="/book-a-demo" className="btn btnPrimary" id="book-a-demo-btn">
          {t("Book a demo")}
        </Link>
        <a
          href="https://www.app.hollydesk.com/create"
          target="_blank"
          rel="noreferrer"
          className="btn btnSecondary"
          id="try-it-out-btn"
          style={{ color: "#1D2B36" }}
        >
          {t("Try it out")}
        </a>
      </div>
    </section>
  );
};

export default LowerBanner;
// A simple
