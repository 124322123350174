import { GET_ARTICLES } from "../actions/actionTypes";

const initialState = {
  articles: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.articles,
      };

    default:
      return state;
  }
};

export default reducer;
