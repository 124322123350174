import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import group1 from "../../../../assets/images/Group 1.png";
import group2 from "../../../../assets/images/Group 2.png";
import group3 from "../../../../assets/images/Group 3.png";
import group4 from "../../../../assets/images/Group 4.png";
import group5 from "../../../../assets/images/Group 5.png";

import "./appFeatures.css";

const AppFeatures = () => {
  const features = [
    {
      name: "Save time",
      title: "Stop wasting hours on approving expense claims",
      content:
        "Managing your company’s expenses has never been easier, with just a click you can create, validate or decline them and it’ll take you minutes instead of hours. Everything can be done directly through Hollydesk",
      link: "/features/expense-reimbursement",
      image: group1,
      color: "#0073A7",
      backgroundColor: "#F2F8FB",
    },
    {
      name: "All in one place",
      title: "No more losing receipts",
      content:
        "Simply upload all your receipts directly into the expense record by just snapping a picture of it anytime, anywhere on the go with your mobile to avoid losing them as well as saving your time.",
      link: "/features/in-advance-payment",
      image: group2,
      color: "#FB923C",
      backgroundColor: "transparent",
    },
    {
      name: "Real-time syncing",
      title: "Automated real-time reporting",
      content:
        "See where the company’s money is going in real-time as an accountant and easily follow and manage expense records digitally across the whole company to keep an eye on costs and control over budgets",
      link: "/features/reporting",
      image: group3,
      color: "#0BB78A",
      backgroundColor: "#F4FDFC",
    },
    {
      name: "Easy to control",
      title: "Invoice payments made easy",
      content:
        "You can easily register suppliers as well as upload all their documents digitally into Hollydesk and get their invoices approved and paid on time.",
      link: "/features/supplier-management",
      image: group4,
      color: "#FFC555",
      backgroundColor: "transparent",
    },
    {
      name: "No limit for your spending",
      title: "Scalable business funding",
      content:
        "We will immediately fund your cash flow to help develop your business, and the more you spend, the higher your credit limit.",
      link: "/features/business-credit",
      image: group5,
      color: "#DC2626",
      backgroundColor: "#FFF8F8",
    },
  ];

  const { t } = useTranslation();

  return (
    <section className="mainPage featuresSection">
      <h1 className="section-title digitalize-title" id="digitalize-title">
        {t("A single platform where you can reimburse, pay employees, and")}{" "}
        <span className="features-background">{t("manage invoices")}</span>{" "}
        {t("in seconds")}
      </h1>
      {features.length === 0
        ? ""
        : features.map((el, i) => {
            return (
              <Fade direction="up" delay={30} triggerOnce key={i}>
                <div
                  className="feature-element"
                  key={i + 1}
                  style={{
                    flexDirection: i & 1 ? "row" : "row-reverse",
                    justifyContent: i & 1 ? "flex-start" : "flex-end",
                    backgroundColor: el.backgroundColor,
                  }}
                >
                  <img
                    src={el.image}
                    alt={el.name}
                    id={`feature-element-img-${i + 1}`}
                  />
                  <div className="feature-element-dialog">
                    <p
                      className="feature-element-name"
                      id={`feature-element-name${i + 1}`}
                      style={{ color: el.color }}
                    >
                      {t(el.name)}
                    </p>
                    <h3 id={`feature-element-title${i + 1}`}>{t(el.title)}</h3>
                    <p
                      className="feature-element-content"
                      id={`feature-element-content${i + 1}`}
                    >
                      {t(el.content)}
                    </p>
                    <Link
                      id={`feature-element-link${i + 1}`}
                      to={el.link}
                      style={{ color: el.color }}
                    >
                      {t("Learn more")}
                    </Link>
                  </div>
                </div>
              </Fade>
            );
          })}
    </section>
  );
};

export default AppFeatures;
//
