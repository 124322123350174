import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./firstBanner.css";

const FirstBanner = () => {
  const { t } = useTranslation();

  return (
    <section className="firstBannerSection">
      <div className="mainPage firstBannerWrapper">
        <h1 className="section-title firstBanner-title" id="firstBanner-title">
          {t("A smart way to")}{" "}
          <span className="landingPage-bannerBackGround">
            {t("take control")}
          </span>{" "}
          {t("over your spend management")}
        </h1>
        <div className="lowerBanner-buttons">
          <Link
            to="/book-a-demo"
            className="btn btnPrimary"
            id="book-a-demo-btn"
          >
            {t("Book a demo")}
          </Link>
          <a
            href="https://www.app.hollydesk.com/create"
            target="_blank"
            rel="noreferrer"
            className="btn btnSecondary"
            id="try-it-out-btn"
            style={{ color: "#1D2B36" }}
          >
            {t("Try it out")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default FirstBanner;
