import React, { Fragment, useEffect } from "react";
import LowerBanner from "../home/lowerBanner/lowerBanner";
import ReviewsMasonry from "./reviewsMasonry/reviewsMasonry";
import reviewsLogos from "../../../assets/images/reviews-logos.png";

const Reviews = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Fragment>
      <section className="mainPage">
        <img
          src={reviewsLogos}
          className="reviewsLogos"
          alt="reviewsLogos"
          id="reviewsLogos"
        />
      </section>
      <ReviewsMasonry />
      <LowerBanner />
    </Fragment>
  );
};

export default Reviews;
