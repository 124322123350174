import React from 'react';
import Avatar from 'antd/lib/avatar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import person from '../../../../../assets/images/human.png';

import './articleBanner.css';

const ArticleBanner = (props) => {
  const { article, showBtn } = props;

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const navigate = useNavigate();

  const color = () => {
    switch (article?.category) {
      case 'Spend Management':
        return '#56C288';
      case 'Finance':
        return '#FFC555';
      case 'Accounting':
        return '#0073A7';
      case 'Supplier Management':
        return '#515A5A';
      case 'Inside Hollydesk':
        return '#DC7633';
      case 'Expense claims':
        return '#F7DC6F';
      case 'Expense Management':
        return '#A569BD';
      case 'What’s New':
        return '#0B5345';
      case 'Tools & Tips':
        return '#CD6155';

      default:
        return '#56C288';
    }
  };

  return (
    <div className="bannerArticle">
      <img
        className="bannerArticle-poster"
        src={article?.coverPhoto?.path}
        alt="banner"
      />
      <div className="bannerArticle-content">
        <span
          className="articleCard-label"
          style={{ backgroundColor: color() }}
        >
          {t(article?.category)}
        </span>
        <h3 className="articleCard-title" id="articleCard-title">
          {lang === 'ar' ? article?.titleAr : article?.title}
        </h3>
        <h2 className="articleCard-desc" id="articleCard-desc">
          {lang === 'ar' ? article?.descriptionAr : article?.description}
        </h2>
        <div className="articleBanner-publish">
          <div className="articleBanner-author">
            <Avatar
              src={article?.user?.image === '' ? person : article?.user?.image}
              size={50}
            />
            <h3 className="articleCard-authorName">{article?.user?.name}</h3>
          </div>
          <p style={{ color: '#4B5563' }}>
            {dayjs(article?.date).format('DD/MM/YYYY')}
          </p>
        </div>
        {showBtn && (
          <button
            className="btn btnPrimary banner-article-btn"
            id="banner-article-btn"
            onClick={() =>
              navigate(`/resources/blog/${article._id}?id=${article._id}`)
            }
          >
            {t('Read the article')}
          </button>
        )}
      </div>
    </div>
  );
};

export default ArticleBanner;
