import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import message from "antd/lib/message";

import blogFormImg from "../../../../../assets/images/blog-form.png";
import api from "../../../../../api";

import "./blogForm.css";

const BlogForm = () => {
  const [email, setEmail] = useState("");
  const [block, setBlock] = useState(false);
  const [done, setDone] = useState(false);

  const { t } = useTranslation();

  const subscribeHnadler = (e) => {
    e.preventDefault();
    return email === ""
      ? message.error("Please enter an Email to subscribe")
      : (setBlock(true),
        api
          .post("/admin/subscription", { email })
          .then((res) => {
            setDone(true);
            setTimeout(() => {
              setBlock(false);
              setDone(false);
            }, 5000);
            setEmail("");
          })
          .catch((err) => {
            message.error("Filed to subscribe");
            setBlock(false);
          }));
  };

  return (
    <div className="blog-form">
      <img src={blogFormImg} alt="blog-form" id="blog-form-image" />
      <div className="blog-form-content">
        <p className="blog-form-line1" id="blog-form-line1">
          {t("Stay in the know")}
        </p>
        <p className="blog-form-line2" id="blog-form-line2">
          {t(
            "Inspiring stories, bast practices & community news. Straight to your box."
          )}
        </p>
        <form className="blog-form-inputGroup" onSubmit={subscribeHnadler}>
          <input
            type="text"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
            className="ant-input"
            title="Email format should be like sometext@website.domain"
            placeholder={t("Enter your email")}
            id="blog-form-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {done ? (
            <button
              className="btn btnGreenFill btnAction"
              id="blog-form-btn"
              disabled={block}
            >
              🎉 {t("Subscribed")} 🎉
            </button>
          ) : (
            <button
              className="btn btnGreenFill btnAction"
              id="blog-form-btn"
              type="submit"
              disabled={block}
            >
              {t("Subscribe")}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default BlogForm;
