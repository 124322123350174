import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import { DownOutlined } from "@ant-design/icons";
import Divider from "antd/lib/divider";

const FeaturesMenu = () => {
  const [dir, setDir] = useState("ltr");

  const { t, i18n } = useTranslation();

  const lang = i18n.language;

  const location = useLocation();

  useEffect(() => {
    lang === "ar" ? setDir("rtl") : setDir("ltr");
  }, [lang]);

  const menu = (
    <Menu>
      <Menu.Item key="0" style={{ direction: dir }}>
        <NavLink to="/features/expense-reimbursement" id="mainNav-link-expRemp">
          {t("Expense Reimbursements")}
        </NavLink>
      </Menu.Item>
      <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="1" style={{ direction: dir }}>
        <NavLink to="/features/in-advance-payment" id="mainNav-link-inAdv">
          {t("In Advance Payment")}
        </NavLink>
      </Menu.Item>
      <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="2" style={{ direction: dir }}>
        <NavLink to="/features/supplier-management" id="mainNav-link-supplier">
          {t("Supplier management")}
        </NavLink>
      </Menu.Item>
      <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="3" style={{ direction: dir }}>
        <NavLink to="/features/reporting" id="mainNav-link-reporting">
          {t("Reporting")}
        </NavLink>
      </Menu.Item>
      <Divider style={{ margin: "4px 0px" }} />
      <Menu.Item key="4" style={{ direction: dir }}>
        <NavLink to="/features/business-credit" id="mainNav-link-business">
          {t("Business Credit")}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className={
          location.pathname === "/features/expense-reimbursement" ||
          location.pathname === "/features/in-advance-payment" ||
          location.pathname === "/features/supplier-management" ||
          location.pathname === "/features/reporting" ||
          location.pathname === "/features/business-credit"
            ? "ant-dropdown-link nav-bar-item-active"
            : "ant-dropdown-link nav-bar-item"
        }
        onClick={(e) => e.preventDefault()}
        id="mainNav-link-features"
      >
        {t("Features")} <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default FeaturesMenu;
