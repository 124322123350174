import inadv1 from "../../../../assets/images/inadv1.png";
import inadv2 from "../../../../assets/images/inadv2.png";
import inadv3 from "../../../../assets/images/inadv3.png";

export const inAdvancePayment = {
  name: "In Advance Payment",
  discription:
    "Employees can request an in advance payment instead of out-of-pocket and then the funds will be transferred to their account once approved.",
  content: [
    {
      keyName: "Simple request",
      title: "Request funds effortlessly",
      paragraph:
        "Employees can simply submit a request for the amount of money required for specific work-related expenses in advance through Hollydesk, rather than out-of-pocket.",
      photo: inadv1,
    },
    {
      keyName: "Instant transfer",
      title: "Easy money transfer",
      paragraph:
        "After being approved, the amount of money will be instantly transferred directly from the company’s digital wallet to the employees’ wallet",
      photo: inadv2,
    },
    {
      keyName: "Fast reconcilation",
      title: "Immediate and efficient processes",
      paragraph:
        "The employee can simply reconcile and upload the receipt after the money has been paid, to ensure the entire amount transferred is spent. If the employee pays any additional money out of pocket, the additional amount will be immediately transferred to his digital wallet after being reviewed by the finance team.",
      photo: inadv3,
    },
  ],
};
