import React from "react";
import { useTranslation } from "react-i18next";

import demo1 from "../../../../assets/images/demo-1.png";
import demo1AR from "../../../../assets/images/demo-1AR.png";
import demo2 from "../../../../assets/images/demo-2.png";
import demo2AR from "../../../../assets/images/demo-2AR.png";
import demo3 from "../../../../assets/images/demo-3.png";
import demo3AR from "../../../../assets/images/demo-3AR.png";
import demo4 from "../../../../assets/images/demo-4.png";
import demo4AR from "../../../../assets/images/demo-4AR.png";

import "./demoBanner.css";

const DemoBanner = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <div className="mainPage demo-banner">
      <h1 className="section-title demo-banner-title" id="demo-banner-title">
        {t("Ready to Save your")}{" "}
        <span className="landingPage-bannerBackGround">
          {t("time & money?")}
        </span>
      </h1>
      <p className="demo-banner-subtitle" id="demo-banner-subtitle">
        {t("Automate your business spending now and REQUEST A DEMO")}
      </p>
      <img
        src={lang === "ar" ? demo1AR : demo1}
        alt="bannerImage"
        className="demo-img-1"
        id="demo-img-1"
      />
      <img
        src={lang === "ar" ? demo2AR : demo2}
        alt="bannerImage"
        className="demo-img-2"
        id="demo-img-2"
      />
      <img
        src={lang === "ar" ? demo3AR : demo3}
        alt="bannerImage"
        className="demo-img-3"
        id="demo-img-3"
      />
      <img
        src={lang === "ar" ? demo4AR : demo4}
        alt="bannerImage"
        className="demo-img-4"
        id="demo-img-4"
      />
    </div>
  );
};

export default DemoBanner;
