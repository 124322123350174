import React from "react";
import { useTranslation } from "react-i18next";

import "./mainHeaders.css";

const MainHeaders = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage referral-mainHeaders">
      <h1
        className="section-title referral-mainHeader-1"
        id="referral-mainHeader-1"
      >
        {t("Save more time and money together and get")}
        <span className="digitalize-background">{t("a reward!")}</span>
      </h1>
      <h1
        className="section-title referral-mainHeader-2"
        id="referral-mainHeader-2"
      >
        {t("Refer Hollydesk to a Company,")}{" "}
        <span className="landingPage-bannerBackGround">{t("get 500 EGP")}</span>{" "}
        {t("reward and they’ll get the first 2 months for free.")}
      </h1>
    </section>
  );
};

export default MainHeaders;
