import React, { Fragment } from "react";
import { Outlet } from "react-router";

import LowerBanner from "../home/lowerBanner/lowerBanner";

const Resources = () => {
  return (
    <Fragment>
      <Outlet />
      <LowerBanner />
    </Fragment>
  );
};

export default Resources;
