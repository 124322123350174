import React from "react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import featuredIn1 from "../../../../assets/images/featured-in-1.png";
import featuredIn2 from "../../../../assets/images/featured-in-2.png";
import featuredIn3 from "../../../../assets/images/featured-in-3.png";
import featuredIn4 from "../../../../assets/images/featured-in-4.png";
import featuredIn5 from "../../../../assets/images/featured-in-5.png";
import featuredIn6 from "../../../../assets/images/featured-in-6.png";
import featuredIn7 from "../../../../assets/images/featured-in-7.png";
import featuredIn8 from "../../../../assets/images/featured-in-8.png";

import "./featuredIn.css";

const FeaturedIn = () => {
  const { t } = useTranslation();

  return (
    <section className="mainPage featuredIn">
      <h1 className="section-title" id="featuredIn-mainTitle">
        {t("Featured in")} 🚀
      </h1>
      <div className="featuredIn-logos" style={{ direction: "ltr" }}>
        <Marquee speed={30} gradientWidth={100}>
          <img
            src={featuredIn1}
            alt="featured in logo"
            id="featured-in-logo-1"
          />
          <img
            src={featuredIn2}
            alt="featured in logo"
            id="featured-in-logo-2"
          />
          <img
            src={featuredIn3}
            alt="featured in logo"
            id="featured-in-logo-3"
          />
          <img
            src={featuredIn4}
            alt="featured in logo"
            id="featured-in-logo-4"
          />
          <img
            src={featuredIn5}
            alt="featured in logo"
            id="featured-in-logo-5"
          />
          <img
            src={featuredIn6}
            alt="featured in logo"
            id="featured-in-logo-6"
          />
          <img
            src={featuredIn7}
            alt="featured in logo"
            id="featured-in-logo-7"
          />
          <img
            src={featuredIn8}
            alt="featured in logo"
            id="featured-in-logo-8"
          />
        </Marquee>
      </div>
    </section>
  );
};

export default FeaturedIn;
