import React from "react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import gameball from "../../../../../assets/images/gameball-logo.png";
import zest from "../../../../../assets/images/zest-logo.png";
import gizaSystems from "../../../../../assets/images/giza-systems-logo.png";
import adam from "../../../../../assets/images/ADAM.png";
import awtad from "../../../../../assets/images/AWTAD.png";
import eyouth from "../../../../../assets/images/EYOUTH.png";
import hvo from "../../../../../assets/images/HVO.png";
import kaart from "../../../../../assets/images/KAART.png";
import schoolarships from "../../../../../assets/images/Scholarships and Awards Summit Logo.png";
import simplex from "../../../../../assets/images/Simplex_Logo.png";
import supernova from "../../../../../assets/images/Supernova Logo.png";
import thndr from "../../../../../assets/images/THNDR.png";
import career from "../../../../../assets/images/CAREER.png";
import turbo from "../../../../../assets/images/turbo.png";
import filetto from "../../../../../assets/images/Filetto.png";

const ReferralTrustedBy = () => {
  const { t } = useTranslation();

  return (
    <section className="trustedBy-wrapper">
      <div className="mainPage trustedBy-section">
        <h1 className="section-title trustedBy-title" id="trustedBy-title">
          {t("All in-one spend management platform for all leading companies")}
        </h1>
        <div className="featuredIn-logos" style={{ direction: "ltr" }}>
          <Marquee
            speed={30}
            gradientWidth={100}
            gradientColor={[243, 244, 246]}
          >
            <img src={gameball} alt="company-logo" id="company-logo-1" />
            <img src={turbo} alt="company-logo" id="company-logo-2" />
            <img src={zest} alt="company-logo" id="company-logo-3" />
            <img src={gizaSystems} alt="company-logo" id="company-logo-4" />
            <img src={adam} alt="company-logo" id="company-logo-5" />
            <img src={awtad} alt="company-logo" id="company-logo-6" />
            <img src={eyouth} alt="company-logo" id="company-logo-7" />
            <img src={hvo} alt="company-logo" id="company-logo-8" />
            <img src={kaart} alt="company-logo" id="company-logo-9" />
            <img src={schoolarships} alt="company-logo" id="company-logo-10" />
            <img src={simplex} alt="company-logo" id="company-logo-11" />
            <img src={supernova} alt="company-logo" id="company-logo-12" />
            <img src={thndr} alt="company-logo" id="company-logo-13" />
            <img src={career} alt="company-logo" id="company-logo-14" />
            <img src={filetto} alt="company-logo" id="company-logo-15" />
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default ReferralTrustedBy;
