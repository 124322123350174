import business1 from "../../../../assets/images/business1.png";
import business2 from "../../../../assets/images/business2.png";
import business3 from "../../../../assets/images/business3.png";
import business4 from "../../../../assets/images/business4.png";

export const businessCredit = {
  name: "Business Credit",
  discription:
    "Get easy and flexible funding to finance your daily expenses, pay rent, invoices, and salaries",
  content: [
    {
      keyName: "Immediately funded",
      title: "Flexible funding",
      paragraph:
        "Immediately get your cash flow funded, the money will be directly transferred to your company’s wallet",
      photo: business1,
    },
    {
      keyName: "With just a click",
      title: "Easy expense payment",
      paragraph:
        "Easily pay employees reimbursements, suppliers, invoices, and all your daily expenses through your Hollydesk’s company wallet with just a click",
      photo: business2,
    },
    {
      keyName: "Push the limits",
      title: "Expandable credit limit",
      paragraph:
        "As you increase your spending, your credit limit increases as well",
      photo: business3,
    },
    {
      keyName: "Automated reports",
      title: "Real-time reporting",
      paragraph:
        "Real-time reports are automatically made to help you keep track of all your payments",
      photo: business4,
    },
  ],
};
