import React from "react";
import { useTranslation } from "react-i18next";
import Collapse from "antd/lib/collapse";
import Divider from "antd/lib/divider";
import { Link } from "react-router-dom";

const UsingHollydesk = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { Panel } = Collapse;

  return (
    <Collapse
      className="FAQ-question"
      // defaultActiveKey={["0"]}
      expandIconPosition={lang === "en" ? "right" : "left"}
      accordion
      ghost
    >
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q10")} key="10">
        <h3 className="FAQ-answer">{t("a10")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q11")} key="11">
        <h3 className="FAQ-answer">{t("a11")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q12")} key="12">
        <h3 className="FAQ-answer">
          {t("a12-1")}, <Link to="/book-a-demo">{t("a12-2")}</Link>
        </h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q13")} key="13">
        <h3 className="FAQ-answer">{t("a13")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q14")} key="14">
        <h3 className="FAQ-answer">{t("a14")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
    </Collapse>
  );
};

export default UsingHollydesk;
