import React from "react";
import { useTranslation } from "react-i18next";
import { Fade, Zoom } from "react-awesome-reveal";

import step1 from "../../../../assets/images/step-1.png";
import step2 from "../../../../assets/images/step-2.png";
import step3 from "../../../../assets/images/step-3.png";
import step4 from "../../../../assets/images/step-4.png";
import arrow1 from "../../../../assets/images/1st-arrow.png";
import arrow2 from "../../../../assets/images/2nd-arrow.png";

import "./steps.css";

const Steps = () => {
  const steps = [
    {
      title: "Register your company in seconds",
      text: "Register your company In a few simple and fast steps, then activate your account in a minute, no need to fill in a lot of information",
      photo: step1,
    },
    {
      title: "Invite your team",
      text: "Invite all your employees, define their roles, and make them use Hollydesk easily",
      photo: step2,
    },
    {
      title: "Charge your wallet",
      text: "You will simply charge your company wallet then, you can transfer and pay any employee in any way",
      photo: step3,
    },
    {
      title: "Your team is now ready",
      text: "Your team can now request any fee or reconciliation and our team will be ready at any time to help",
      photo: step4,
    },
  ];

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <section className="mainPage stepsSection">
      <h1 className="section-title stepsSection-title" id="stepsSection-title">
        {t("Simple steps to")}{" "}
        <span className="landingPage-bannerBackGround">
          {t("take control")}
        </span>{" "}
        {t("over of your spend")}
      </h1>
      {steps.length > 0
        ? steps.map((step, i) => {
            return (
              <Fade direction="up" delay={30} triggerOnce key={i}>
                <div
                  className="step-element"
                  key={i + 1}
                  style={
                    lang === "ar"
                      ? {
                          flexDirection: i & 1 ? "row" : "row-reverse",
                          justifyContent: i & 1 ? "flex-start" : "flex-end",
                        }
                      : {
                          flexDirection: i & 1 ? "row-reverse" : "row",
                          justifyContent: i & 1 ? "flex-start" : "flex-end",
                        }
                  }
                >
                  <div className="step-element-dialog">
                    <span className="step-index" id={`step-element-${i + 1}`}>
                      {i + 1}
                    </span>
                    <div className="">
                      <h3 className="step-title" id={`step-title-${i + 1}`}>
                        {t(step.title)}
                      </h3>
                      <p className="step-content" id={`step-content-${i + 1}`}>
                        {t(step.text)}
                      </p>
                    </div>
                  </div>
                  <img src={step.photo} alt="step" id={`step-image-${i + 1}`} />
                </div>
              </Fade>
            );
          })
        : ""}
      <Zoom className="steps-1-2-arrow" triggerOnce>
        <img src={arrow1} id="steps-1-2-arrow" alt="arrow" />
      </Zoom>
      <Zoom className="steps-2-3-arrow" triggerOnce>
        <img src={arrow2} id="steps-2-3-arrow" alt="arrow" />
      </Zoom>
      <Zoom className="steps-3-4-arrow" triggerOnce>
        <img src={arrow1} id="steps-3-4-arrow" alt="arrow" />
      </Zoom>
    </section>
  );
};

export default Steps;
