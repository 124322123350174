import React, { useEffect } from 'react';

const Privecy = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div
      style={{
        maxWidth: 750,
        margin: 'auto',
        marginBlock: 66,
        paddingInline: 26,
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        HollyDesk INC, a company registered in Delaware, US, helps small and
        intermediate companies manage and monitor their expenses in an easy and
        efficient way.
      </p>
      <h2>Data collection and processing</h2>
      <h3>What data is collected by HollyDesk?</h3>
      <ul>
        <li>
          Upon your registration, your personal data (the data that identifies
          you) is collected and stored on our secure servers.
        </li>
        <li>
          We collect the data used in the registration process (e.g., Name,
          email… etc.)
        </li>
        <li>
          When using our services, we may collect your IP address to help
          provide the suitable services according to your country.
        </li>
        <li>
          Your username and password (hashed) are stored in our secure database
          to help you access our services.
        </li>
      </ul>
      <h3>Is your data safe on HollyDesk?</h3>
      <ul>
        <li>
          Yes, your data is securely saved in our database. A security check is
          regularly run on our systems to make sure everything is safe and
          sound. We also check for vulnerabilities on regular basis.
        </li>
        <li>
          Our security and testing teams work hard to keep everything on our
          system as secure and safe as possible, so no need to worry.
        </li>
      </ul>
      <h2>Transparency</h2>
      <p>
        In HollyDesk, we thrive for proficiency and transparency, where
        everything is clear and bright.
      </p>
      <h3>Who can do what?</h3>
      <ul>
        <li>You can access your data anytime using HollyDesk.</li>
        <li>You can ask for data deletion, and we will get it done for you.</li>
      </ul>
      <h3>Is your password safe?</h3>
      <ul>
        <li>
          We never save passwords as plain text, your password is hashed and
          securely saved in our database.
        </li>
      </ul>
      <h3>Do we share your data?</h3>
      <ul>
        <li>
          No, we never share your data with any third-party, nor do we sell it,
          nor do we use it for any kind of advertising.
        </li>
      </ul>
      <h3>Do we use cookies?</h3>
      <ul>
        <li>
          Yes, we use cookies when you visit our website, cookies cannot delete
          or access the data on your PC or Phone.
        </li>
        <li>
          Cookies are used to make login and visiting certain pages a lot easier
          and faster.
        </li>
      </ul>
    </div>
  );
};

export default Privecy;
