import { GET_PLANS } from './actionTypes';

const PLANS = {
  Egypt: [
    {
      id: 0,
      name: 'Standard',
      duration: 1,
      discription:
        'Build the operational muscle your company needs to continue its growth',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      price: 10,
      isBest: false,
    },
    {
      id: 1,

      name: 'Growth',
      duration: 6,
      discription:
        'Support your growing business with a complete expense management tool to control your spending and increase efficiency',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      isBest: true,
      price: 9,
    },
    {
      id: 2,

      name: 'Advanced',
      duration: 12,
      discription: 'A very smart plan for less spending and more saving.',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      isBest: false,
      price: 8,
    },
  ],
  Any: [
    {
      id: 0,
      name: 'Standard',
      duration: 1,
      discription:
        'Build the operational muscle your company needs to continue its growth',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      price: 4,
      isBest: false,
    },
    {
      id: 1,

      name: 'Growth',
      duration: 6,
      discription:
        'Support your growing business with a complete expense management tool to control your spending and increase efficiency',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      isBest: true,
      price: 3,
    },
    {
      id: 2,

      name: 'Advanced',
      duration: 12,
      discription: 'A very smart plan for less spending and more saving.',
      utilities: [
        'Unlimited Users',
        'Unlimited Storage',
        'Unlimited Daily Transfer',
        'Live Customer Support',
        'Access to Business Credit',
        'Expense Claims',
        'Invoice Management',
      ],
      isBest: false,
      price: 2,
    },
  ],
};

export const getPlans = (country) => (dispatch) => {
  dispatch({
    type: GET_PLANS,
    plans: PLANS[country],
  });
  // api
  //   .get(`plans/landing-page?country=${country}`)
  //   .then((res) => {
  //     console.log(res);
  //     dispatch({
  //       type: GET_PLANS,
  //       plans: res.data.plans,
  //     });
  //   })
  //   .catch((err) => console.log(err));
};
