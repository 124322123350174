import React from "react";
import { useTranslation } from "react-i18next";
import Collapse from "antd/lib/collapse";
import Divider from "antd/lib/divider";

const BasicsFAQs = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { Panel } = Collapse;

  return (
    <Collapse
      className="FAQ-question"
      // defaultActiveKey={["0"]}
      expandIconPosition={lang === "en" ? "right" : "left"}
      accordion
      ghost
    >
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q1")} key="1">
        <h3 className="FAQ-answer">{t("a1")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q2")} key="2">
        <h3 className="FAQ-answer">{t("a2-1")}</h3>
        <h4 className="FAQ-answerHead">{t("a2-2")}</h4>
        <h3 className="FAQ-answer">{t("a2-3")}</h3>
        <h4 className="FAQ-answerHead">{t("a2-4")}</h4>
        <h3 className="FAQ-answer">{t("a2-5")}</h3>
        <h4 className="FAQ-answerHead">{t("a2-6")}</h4>
        <h3 className="FAQ-answer">{t("a2-7")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q3")} key="3">
        <h3 className="FAQ-answer">{t("a3")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q4")} key="4">
        <h3 className="FAQ-answer">{t("a4")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q5")} key="5">
        <h3 className="FAQ-answer">{t("a5")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q6")} key="6">
        <h3 className="FAQ-answer">{t("a6")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q7")} key="7">
        <h3 className="FAQ-answer">{t("a7")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q8")} key="8">
        <h3 className="FAQ-answer">{t("a8")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
      <Panel header={t("q9")} key="9">
        <h3 className="FAQ-answer">{t("a9")}</h3>
      </Panel>
      <Divider style={{ borderTop: "1px solid #9CA3AF" }} />
    </Collapse>
  );
};

export default BasicsFAQs;
