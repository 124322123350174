import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Divider from 'antd/lib/divider';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import { DownOutlined } from '@ant-design/icons';

import hollydesk from '../../../assets/images/hollydesk-logo.png';
import facebook from '../../../assets/images/facebook.svg';
import instagram from '../../../assets/images/instagram.svg';
import twitter from '../../../assets/images/twitter.svg';
import linkedin from '../../../assets/images/linkedin.svg';

import './footer.css';

const LandingPageFooter = () => {
  const { t, i18n } = useTranslation();

  const lang = i18n.language;

  const langChangeHandler = (lang) => i18n.changeLanguage(lang);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          langChangeHandler('ar');
        }}
      >
        <span className="lang-name">{t('Arabic')}</span>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          langChangeHandler('en');
        }}
      >
        <span className="lang-name">{t('English')}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <footer className="landingPageFooter">
      <div className="mainPage">
        <div className="footer-shortcuts">
          <div className="footer-vertical-menu">
            <img src={hollydesk} alt="hollydesk logo" />
            <div className="profile-links">
              <img
                className="hollydesk-profile-link"
                src={facebook}
                alt="facebook logo"
              />
              <img
                className="hollydesk-profile-link"
                src={instagram}
                alt="instagram logo"
              />
              <img
                className="hollydesk-profile-link"
                src={twitter}
                alt="twitter logo"
              />
              <img
                className="hollydesk-profile-link"
                src={linkedin}
                alt="linkedin logo"
              />
            </div>
          </div>
          <div className="footer-vertical-menu">
            <h3>{t('Company')}</h3>
            <Link to="/">{t('Why Hollydesk')}</Link>
            <a href="#">{t('Careers')}</a>
            <Link to="/resources/FAQs">{t('FAQs')}</Link>
          </div>
          <div className="footer-vertical-menu">
            <h3>{t('Product')}</h3>
            <Link to="/howItWorks">{t('How it works')}</Link>
            <Link to="/features/expense-reimbursement">{t('Features')}</Link>
            <Link to="/pricing">{t('Pricing')}</Link>
            <Link to="/resources/referral">{t('Offers')}</Link>
          </div>
          <div className="footer-vertical-menu">
            <h3>{t('Resources')}</h3>
            <Link to="/resources/blog">{t('Blog')}</Link>
            <a
              href={`https://intercom.help/hollydesk/${lang}/`}
              target="_blank"
            >
              {t('Helpdesk')}
            </a>
            <Link to="/book-a-demo">{t('Demo')}</Link>
            <a
              href="https://www.app.hollydesk.com/create"
              target="_blank"
              rel="noreferrer"
            >
              {t('Try Hollydesk')}
            </a>
          </div>
          <div className="footer-vertical-menu">
            <h3>{t('Contact')}</h3>
            <Link to="/book-a-demo">{t('Contact us')}</Link>
          </div>
        </div>
        <Divider style={{ borderTop: '1px solid #9CA3AF' }} />
        <div className="footer-bottom">
          <div className="footer-copyrights">
            <span className="copyright-item hollydesk-copyright">
              Hollydesk INC
            </span>
            <span className="copyright-item copyright-hovered">
              {t('Terms of Service')}
            </span>
            <span className="copyright-item copyright-hovered">
              <Link to="/privacy-policy">{t('Privacy Policy')}</Link>
            </span>
          </div>
          <div className="footer-lang">
            <span>{t('Language')}:</span>
            <Dropdown overlay={menu} trigger={['click']}>
              <div className="lang-option nav-bar-item">
                <span className="lang-name">
                  {lang === 'ar' ? t('Arabic') : t('English')}
                </span>
                <DownOutlined className="lang-icon" />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingPageFooter;
// ©
