import React from "react";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";

import NavBar from "./navBar/navBar";
import LandingPageFooter from "./footer/footer";

const LandingPage = () => {
  const location = useLocation();

  return (
    <div>
      <NavBar />
      <Outlet />
      {location.pathname !== "/login" && <LandingPageFooter />}
    </div>
  );
};

export default LandingPage;
